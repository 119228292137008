import {
  useAuthorizationEdit,
  useAuthorizationAdmin,
} from 'modules/auth/authorization';
import { Navigate } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};
function redirect(authorize: boolean, children: React.ReactNode) {
  return <>{authorize ? children : <Navigate to="/" replace={true} />}</>;
}

export const RedirectionAuthorizationEdit = ({ children }: Props) => {
  const { authorize } = useAuthorizationEdit();
  return redirect(authorize, children);
};

export const RedirectionAuthorizationAdmin = ({ children }: Props) => {
  const { authorize } = useAuthorizationAdmin();
  return redirect(authorize, children);
};
