import { Checkbox } from '@chakra-ui/react';

export type CheckBoxProps = { checked: boolean; variant: CheckBoxVariant };
export type CheckBoxVariant = 'blue' | 'grey';
export enum EnumCheckBoxVariant {
  BLUE = 'blue',
  GREY = 'grey',
}

export const CustomCheckBox = ({ checked, variant }: CheckBoxProps) => {
  let checkbox = <Checkbox cursor="default" isChecked={checked} />;
  if (variant === EnumCheckBoxVariant.BLUE) {
    checkbox = (
      <Checkbox
        cursor="default"
        isChecked={checked}
        borderColor="saur.blueCheckbox.500"
        colorScheme="saur.blueCheckbox"
      />
    );
  } else if (variant === EnumCheckBoxVariant.GREY) {
    checkbox = (
      <Checkbox
        cursor="default"
        isChecked={checked}
        borderColor="saur.greyCheckbox.500"
        colorScheme="saur.greyCheckbox"
      />
    );
  }

  return checkbox;
};
