import { StoreType } from 'store';

export type HeaderSlice = {
  profilMenu: boolean;
  toggleProfilMenu: () => void;
  languageIndex: number;
  changeLanguage: (index: number) => void;
};

export const createHeaderSlice: StoreType<HeaderSlice> = (set) => ({
  profilMenu: false,
  toggleProfilMenu: () => set((state) => ({ profilMenu: !state.profilMenu })),
  languageIndex: 0,
  changeLanguage: (index: number) => set(() => ({ languageIndex: index })),
});
