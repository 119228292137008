import create, { StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createHeaderSlice, HeaderSlice } from 'components/header/header-slice';

export type Store = HeaderSlice;
export type StoreType<T> = StateCreator<
  Store,
  [['zustand/devtools', never]],
  [],
  T
>;

export const useStore = create<Store>()(
  devtools((...a) => ({
    ...createHeaderSlice(...a),
  })),
);
