import { MatomoAction } from 'helpers/matomoTraking';
import { DataType } from 'helpers/result';

export type FilterButton = {
  value: number;
  label: string;
  element?: React.ReactNode;
  variant?: ButtonVariant;
  matomoAction: MatomoAction;
  filterType?: DataType;
};

export type ButtonVariant =
  | 'object'
  | 'data'
  | 'indicator'
  | 'customers'
  | 'operations'
  | 'transverseFunctions'
  | 'indicators';

export enum EnumButtonVariant {
  OBJECT = 'object',
  DATA = 'data',
  INDICATOR = 'indicator',
  CUSTOMERS = 'customers',
  OPERATIONS = 'operations',
  TRANSVERSE_FUNCTIONS = 'transverseFunctions',
  INDICATORS = 'indicators',
}
