import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenButton } from 'components/common/Button';
import { launchExport } from './api';

export const ExportButton = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    await launchExport();
    setLoading(false);
  };

  return (
    <GreenButton variant="outline" onClick={handleExport} isLoading={loading}>
      {t('admin.menu.export-import.actionExport')}
    </GreenButton>
  );
};
