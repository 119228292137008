import { ResultPath } from './ResultPath';
import { TagWrapper } from 'components/common/Tag';
import { SearchBar } from './SearchBar';
import { useTranslation } from 'react-i18next';
import { useAuthorizationEdit } from 'modules/auth/authorization';
import { LocalStorage } from 'utils/localStorage';
import { GreenButton } from 'components/common/Button';
import { Flex, Heading, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

type Props = {
  children: React.ReactNode;
  id: number;
  searchPath: any;
  title: string;
  definition: string;
  dataType: number;
};

export const BaseDetails = ({
  children,
  searchPath,
  title,
  definition,
  dataType,
}: Props) => {
  const { t } = useTranslation();
  const { authorize } = useAuthorizationEdit();

  return (
    <VStack>
      <ResponsiveBox gap={6}>
        <SearchBar searchValue={LocalStorage.searchString.get() ?? ''} />
        <Flex
          flexDir="column"
          gap={5}
          bgColor="white"
          borderRadius="2xl"
          p={5}
          mb={4}
          w="full"
        >
          <Flex flexDir="row">
            <HStack spacing={2}>
              <Heading as="h1" id="title" fontSize="md">
                {title.toUpperCase()}
              </Heading>
              <TagWrapper dataType={dataType} />
            </HStack>
            <Spacer />
            {authorize ? (
              <GreenButton variant="outline">{t('edit')}</GreenButton>
            ) : null}
          </Flex>
          <ResultPath pathList={searchPath} />
          <Flex flexDir="column">
            <Heading
              id="definition-title"
              fontWeight="normal"
              fontSize="sm"
              color="saur.greyTabs"
            >
              {t('definition')}
            </Heading>
            <Text id="definition" fontSize="sm">
              {definition || t('toBeCompleted')}
            </Text>
          </Flex>
          {children}
        </Flex>
      </ResponsiveBox>
    </VStack>
  );
};
