import './Header.css';
import { Box } from '@chakra-ui/react';
import { HeaderProfilMenu } from './HeaderProfilMenu';

type Props = {
  children: React.ReactNode;
};
export const HeaderLayout = ({ children }: Props) => {
  const cover = true;

  return (
    <>
      <Box
        as="header"
        className={cover ? 'headerCover' : ''}
        position="absolute"
        zIndex="sticky"
        w="full"
        h="7vw"
      >
        <Box h="7vw">
          <Box h="4.5vw" bg={!cover ? '#F5F8FC' : 'none'}>
            <Box
              h="full"
              bg={!cover ? '#243977' : 'none'}
              borderBottomRightRadius="3xl"
            >
              {children}
            </Box>
          </Box>
          <Box h="2.5vw" bg={!cover ? '#243977' : 'none'}>
            <Box
              h="full"
              bg={!cover ? '#F5F8FC' : 'none'}
              borderTopLeftRadius="3xl"
            ></Box>
          </Box>
        </Box>
      </Box>
      <HeaderProfilMenu />
    </>
  );
};
