import { httpAdmin } from 'utils/http';
import { ImportReport } from './type';

export const launchExport = async () => {
  const response = await httpAdmin.get(`/Export`, {
    responseType: 'blob',
  });
  const fileName = response.request
    .getResponseHeader('Content-Disposition')
    .split('filename=')[1]
    .split(';')[0];
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response.data);
  link.download = fileName;
  link.click();
};

export const uploadImportFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  try {
    const { data } = await httpAdmin.post<ImportReport>(`/Import`, formData, {
      headers: {
        'Content-Type': file.type,
      },
    });
    return {data: data, state: 'Success'};
  } catch (e) {
    return {data: [], state: 'Error', errorMsg: e};
  }
};

export const validateImport = async () => {
  try {
    const { data } = await httpAdmin.get<string>(`/Import/validate`);
    return data;
  } catch(e) {
    return e;
  }
};
