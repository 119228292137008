import { Flex } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Header } from 'components/header';
import { LoadingSpinner } from './LoadingSpinner';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Header />
      <Flex flexDir="column" mt={28}>
        <Outlet />
      </Flex>
    </Suspense>
  );
};
