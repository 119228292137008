import { Wrap, WrapItem } from '@chakra-ui/react';
import { MenuCard } from './types';
import { MenuCardItem } from './MenuCardItem';

export type Props = {
  list: MenuCard[];
};
export const MenuCardList = ({ list }: Props) => {
  const listWrapped = list.map((item) => (
    <WrapItem key={item.id}>
      <MenuCardItem {...item} />
    </WrapItem>
  ));
  return (
    <Wrap spacing={8} align="center" justify="center">
      {listWrapped}
    </Wrap>
  );
};
