import { SearchBar } from 'features/search';
import { useTranslation } from 'react-i18next';
import { BasePageContainer } from 'components/common/Page';
import { StatCard, StatCardList, useStat } from 'features/stat';
import { Heading, VStack } from '@chakra-ui/react';
import ObjectLogo from 'assets/stat/object.svg';
import DataLogo from 'assets/stat/data.svg';
import IndicatorLogo from 'assets/stat/indicator.svg';
import { LoadingSpinner } from 'components/layout';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

export const SearchPage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useStat();
  const cards: StatCard[] = [
    {
      id: 'object',
      title: t('stat.statTitle.object'),
      result: data?.objectCount ?? 0,
      logo: ObjectLogo,
    },
    {
      id: 'data',
      title: t('stat.statTitle.data'),
      result: data?.dataCount ?? 0,
      logo: DataLogo,
    },
    {
      id: 'indicator',
      title: t('stat.statTitle.indicator'),
      result: data?.indicatorCount ?? 0,
      logo: IndicatorLogo,
    },
  ];

  return (
    <BasePageContainer title={t('homeTitle')} marginTop={true}>
      <ResponsiveBox gap={20}>
        <SearchBar />
        {isLoading && !data ? (
          <LoadingSpinner />
        ) : (
          <VStack spacing={5}>
            <Heading fontSize="xl" fontWeight="normal">
              {t('stat.title')}
            </Heading>
            <StatCardList list={cards} />
          </VStack>
        )}
      </ResponsiveBox>
    </BasePageContainer>
  );
};
