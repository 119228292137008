import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getSearch, getObject, getData, getIndicator } from './api';

type Params = {
  id: string;
};

export function useParamsSearchDetail() {
  const { id, ...rest } = useParams<Params>();
  return {
    ...rest,
    id: parseInt(id!),
  };
}

export function useSearch(search: string | null) {
  const { i18n } = useTranslation();

  return useQuery(
    ['search', search, i18n.language],
    () => getSearch(search ?? '', i18n.language),
    {
      enabled: !!search && !!i18n.language,
      refetchOnWindowFocus: false,
      staleTime: 10000,
    },
  );
}

export function usePrefetchObject(objectId: number) {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const prefetch = async () => {
    await queryClient.prefetchQuery(
      ['object', objectId, i18n.language],
      () => getObject(objectId, i18n.language),
      { staleTime: 10000 },
    );
  };

  return { prefetch };
}

export function useObject(objectId: number) {
  const { i18n } = useTranslation();

  return useQuery(
    ['object', objectId, i18n.language],
    () => getObject(objectId, i18n.language),
    {
      enabled: !!objectId && !!i18n.language,
      staleTime: 10000,
    },
  );
}

export function usePrefetchData(dataId: number) {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const prefetch = async () => {
    await queryClient.prefetchQuery(
      ['data', dataId, i18n.language],
      () => getData(dataId, i18n.language),
      { staleTime: 10000 },
    );
  };

  return { prefetch };
}

export function useData(dataId: number) {
  const { i18n } = useTranslation();

  return useQuery(
    ['data', dataId, i18n.language],
    () => getData(dataId, i18n.language),
    {
      enabled: !!dataId && !!i18n.language,
      staleTime: 10000,
    },
  );
}

export function usePrefetchIndicator(indicatorId: number) {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const prefetch = async () => {
    await queryClient.prefetchQuery(
      ['indicator', indicatorId, i18n.language],
      () => getIndicator(indicatorId, i18n.language),
      { staleTime: 10000 },
    );
  };

  return { prefetch };
}

export function useIndicator(indicatorId: number) {
  const { i18n } = useTranslation();

  return useQuery(
    ['indicator', indicatorId, i18n.language],
    () => getIndicator(indicatorId, i18n.language),
    {
      enabled: !!indicatorId && !!i18n.language,
      staleTime: 10000,
    },
  );
}
