import { useEffect } from 'react';
import { DataTabs, DataRow } from 'components/GridPanel';
import {
  BaseDetails,
  ObjectDataList,
  QualityTab,
  TabsPanelDetails,
  useObject,
  useParamsSearchDetail,
  Path,
} from 'features/search';
import { SwitchToggleYesNo } from 'components/common/SwitchToggle';
import { TagWrapperDispo } from 'components/common/Tag';
import { CommentSection } from 'components/common/CommentSection';
import { DataType } from 'helpers/result';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LoadingSpinner } from 'components/layout/LoadingSpinner';
import { AiOutlineUser } from 'react-icons/ai';
import { Icon } from '@chakra-ui/react';

export const ObjectDetailsPage = () => {
  const { id } = useParamsSearchDetail();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  const { isLoading, data } = useObject(id);

  // Track page view
  useEffect(() => {
    trackPageView({ documentTitle: document.title, href: document.URL });
  }, [trackPageView]);

  if (isLoading || !data) return <LoadingSpinner />;

  const searchPath: Path[] = [
    {
      title: t('searchResultPath.domain'),
      label: data.domain,
    },
    {
      title: t('searchResultPath.subdomain'),
      label: data.subDomain,
    },
    {
      title: t('searchResultPath.businessObject'),
      label: data.label,
    },
  ];

  const businessTab: DataRow[] = [
    [
      {
        title: t('itemTab.businessTab.dataManager'),
        content: data.dataManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataOwner'),
        content: data.productionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataCustodian'),
        content: data.provisionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
    ],
    [
      {
        title: t('itemTab.dataPlatformTab.coreObjectModeled'),
        content: <SwitchToggleYesNo data={data.modeledData} />,
      },
      {
        title: t('itemTab.dataPlatformTab.dataPlateformAvailability'),
        content: <TagWrapperDispo data={data.availableData} />,
      },
    ],
    <CommentSection text={data.comment} />,
  ];

  const tabsArray: DataTabs[] = [
    {
      label: t('itemTab.names.businessTab'),
      value: 'business',
      panel: businessTab,
    },
    {
      label: t('itemTab.names.quality'),
      value: 'quality',
      panel: <QualityTab quality={data.quality} dataType={DataType.Object} />,
    },
  ];
  if (data.datas?.length > 0) {
    tabsArray.push({
      label: t('itemTab.names.dataList'),
      value: 'dataList',
      panel: <ObjectDataList dataList={data.datas} />,
    });
  }

  return (
    <BaseDetails
      id={data.id}
      searchPath={searchPath}
      title={data.label}
      definition={data.definition}
      dataType={DataType.Object}
    >
      <TabsPanelDetails tabs={tabsArray} dataType={DataType.Object} />
    </BaseDetails>
  );
};
