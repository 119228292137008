import { useState, useEffect } from 'react';
import * as Helper from 'helpers/result';
import * as DomainHelper from 'helpers/domain';
import { useTranslation } from 'react-i18next';
import { Box, Tag } from '@chakra-ui/react';

export const TagWrapper = ({ dataType }: { dataType: number }) => {
  const { t } = useTranslation();

  let bgColor = 'saur.black';
  let color = 'white';
  let title = '';
  if (Helper.isObject(dataType)) {
    bgColor = 'saur.object';
    title = t('dataType.object');
  } else if (Helper.isData(dataType)) {
    bgColor = 'saur.data';
    title = t('dataType.data');
  } else if (Helper.isIndicator(dataType)) {
    bgColor = 'saur.indicator';
    title = t('dataType.indicator');
    color = 'saur.black';
  }

  return (
    <Tag color={color} bgColor={bgColor} size="sm" borderRadius="full">
      {title}
    </Tag>
  );
};

export const TagWrapperDispo = ({ data }: { data?: boolean | string }) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [dispo, setDispo] = useState(false);
  if (typeof data === 'string') {
    data = data.toLowerCase();
  }

  useEffect(() => {
    if (data === true || data === 'yes') {
      setDisabled(false);
      setDispo(true);
    } else if (data === false || data === 'no') {
      setDisabled(false);
      setDispo(false);
    } else if (data === '' || data === null || data === undefined) {
      setDisabled(true);
      setDispo(false);
    }
  }, [data]);

  let bgColor = '';
  let title = '';
  if (disabled) {
    bgColor = 'saur.undefined';
    title = t('haveData.undefined');
  } else if (dispo) {
    bgColor = 'saur.available';
    title = t('haveData.available');
  } else {
    bgColor = 'saur.unavailable';
    title = t('haveData.unavailable');
  }

  return (
    <Tag
      bgColor={bgColor}
      color="saur.black"
      size="md"
      borderRadius="full"
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      {title}
    </Tag>
  );
};

export const TagDomainWrapper = ({ domain }: { domain: string }) => {
  let bgColor = 'saur.black';

  if (DomainHelper.isCustomers(domain)) {
    bgColor = 'saur.domain.customers';
  } else if (DomainHelper.isOperations(domain)) {
    bgColor = 'saur.domain.operations';
  } else if (DomainHelper.isTransverseFunctions(domain)) {
    bgColor = 'saur.domain.transverseFunctions';
  } else if (DomainHelper.isIndicators(domain)) {
    bgColor = 'saur.domain.indicators';
  }

  return (
    <Box
      as="span"
      borderRadius="0.594rem"
      w="3.625rem"
      h="1.188rem"
      bg={bgColor}
    />
  );
};
