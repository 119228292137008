import { useRef, useState } from 'react';
import { SearchBarOption } from './SearchBarOption';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoCategory, MatomoAction } from 'helpers/matomoTraking';
import {
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useSearch } from './hooks';
import { NoResult } from 'components/layout';

type Props = {
  searchValue?: string;
};
export const SearchBar = ({ searchValue }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchValue ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  const { data, isLoading, fetchStatus } = useSearch(
    debouncedSearchTerm === '' ? null : debouncedSearchTerm,
  );
  const barRef = useRef(null);
  useOnClickOutside(barRef, () => setOpen(false));

  const resultPage = () => {
    if (debouncedSearchTerm === '') {
      return;
    }
    console.log('in result action');

    trackEvent({
      category: MatomoCategory.searchBar,
      action: MatomoAction.clickSearch,
    });

    navigate(`/result?searchstring=${debouncedSearchTerm}`);
  };

  return (
    <Flex flexDir="column" w="full" position="relative" ref={barRef}>
      <InputGroup>
        <Input
          rounded="full"
          borderColor="#243977"
          focusBorderColor="#243977"
          _hover={{ borderColor: '#243977' }}
          bg="white"
          size="lg"
          placeholder={t('searchBar')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={() => setOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              resultPage();
            }
          }}
        />
        <InputRightElement
          children={
            <HStack mr={10} mt={2}>
              {isLoading && fetchStatus !== 'idle' ? (
                <Spinner color="blue.500" size="sm" />
              ) : null}
              <IconButton
                aria-label="Search"
                bg="none"
                ml={4}
                rounded="full"
                onClick={resultPage}
                icon={<SearchIcon color="saur.black" boxSize={5} />}
              />
            </HStack>
          }
        />
      </InputGroup>
      {open && !isLoading && debouncedSearchTerm !== '' ? (
        <Flex
          direction="column"
          gap={4}
          bg="white"
          h="3xs"
          mt={14}
          w="full"
          p={5}
          zIndex="dropdown"
          position="absolute"
          overflowY="scroll"
          scrollBehavior="smooth"
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <SearchBarOption
                key={`search-bar-${index}`}
                result={item}
                searchValue={debouncedSearchTerm}
              />
            ))
          ) : (
            <NoResult />
          )}
        </Flex>
      ) : null}
    </Flex>
  );
};
