export enum MatomoCategory {
  language = 'language',
  searchBar = 'search-bar',
  searchResultPage = 'search-result-page',
  objectDetailsPage = 'object-details-page',
  dataDetailsPage = 'data-details-page',
  indicatorDetailsPage = 'indicator-details-page',
}
export const MatomoActionDynamic = {
  changeLangTo: (lang: string) => `change-lang-to-${lang}`,
};

export enum MatomoAction {
  clickSearch = 'click-search',

  clickDataDetailsInObjectDetailsPage = 'click-data-details-in-object-details-page',

  clickFilterAllInSearchResultPage = 'click-filter-all-in-search-result-page',
  clickFilterObjectInSearchResultPage = 'click-filter-object-in-search-result-page',
  clickFilterDataInSearchResultPage = 'click-filter-data-in-search-result-page',
  clickFilterIndicatorInSearchResultPage = 'click-filter-indicator-in-search-result-page',

  clickObjectDetailsInSearchResultPage = 'click-object-details-in-search-result-page',
  clickDataDetailsInSearchResultPage = 'click-data-details-in-search-result-page',
  clickIndicatorDetailsInSearchResultPage = 'click-indicator-details-in-search-result-page',

  clickObjectExpandMoreInfoInSearchResultPage = 'click-object-expand-more-info-in-search-result-page',
  clickDataExpandMoreInfoInSearchResultPage = 'click-data-expand-more-info-in-search-result-page',
  clickIndicatorExpandMoreInfoInSearchResultPage = 'click-indicator-expand-more-info-in-search-result-page',

  clickTabInObjectDetailsPage = 'click-tab-in-object-details-page',
  clickTabInDataDetailsPage = 'click-tab-in-data-details-page',
  clickTabInIndicatorDetailsPage = 'click-tab-in-indicator-details-page',

  clickObjectDetailsInSearchBar = 'click-object-details-in-search-bar',
  clickDataDetailsInSearchBar = 'click-data-details-in-search-bar',
  clickIndicatorDetailsInSearchBar = 'click-indicator-details-in-search-bar',

  clickFilterInDataHeatMapPage = 'click-filter-in-data-heatmap-page',
  clickFilterAllInDataHeatMapPage = 'click-filter-all-in-data-heatmap-page',
  clickFilterCustomersInDataHeatMapPage = 'click-filter-customers-in-data-heatmap-page',
  clickFilterOperationsInDataHeatMapPage = 'click-filter-operations-in-data-heatmap-page',
  clickFilterTransverseFunctionsInDataHeatMapPage = 'click-filter-transverse-functions-in-data-heatmap-page',
  clickFilterIndicatorsInDataHeatMapPage = 'click-filter-indicators-in-data-heatmap-page',
}
