import { Text, VStack } from '@chakra-ui/react';

type Props = {
  error: Error;
  resetErrorBoundary?: () => void;
};
export const Error = ({ error }: Props) => {
  return (
    <VStack>
      <Text>Error</Text>
      <Text>{error.message}</Text>
    </VStack>
  );
};
