import { MenuCard, MenuCardList } from 'components/MenuCard';
import ExportImportLogo from 'assets/admin/export-import.png';
import { useTranslation } from 'react-i18next';
import { BasePageContainer } from 'components/common/Page';
import { HStack } from '@chakra-ui/react';
import { ExportButton, ImportButton } from 'features/export-import';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

export const AdminPage = () => {
  const { t } = useTranslation();
  const cards: MenuCard[] = [
    {
      id: 'export-import',
      title: t('admin.menu.export-import.title'),
      description: t('admin.menu.export-import.description'),
      logo: ExportImportLogo,
      action: (
        <HStack spacing={4}>
          <ExportButton />
          <ImportButton />
        </HStack>
      ),
    },
  ];
  return (
    <BasePageContainer title={t('admin.title')}>
      <ResponsiveBox>
        <MenuCardList list={cards} />
      </ResponsiveBox>
    </BasePageContainer>
  );
};
