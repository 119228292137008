import { chakra } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const HeaderNavItem = chakra(Link, {
  baseStyle: {
    color: 'white',
    fontSize: 'sm',
    textAlign: 'center',
    lineHeight: '1rem',
  },
});
