import { useRef } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useOverflow } from 'utils/hooks/useOverflow';
import { WrapTooltip } from 'components/common/WrapTooltip';
import { Link as RouterLink } from 'react-router-dom';

export type Path = {
  title: string;
  label: string;
  link?: string;
};

const ResultPathItem = ({ title, label, link }: Path) => {
  const labelRef = useRef(null);
  const overflow = useOverflow(labelRef);

  const labelField = (
    <WrapTooltip isDisabled={!overflow} label={label}>
      <Text ref={labelRef} fontSize="sm" maxW="10rem" noOfLines={1}>
        {label}
      </Text>
    </WrapTooltip>
  );

  return (
    <Flex direction="column">
      <Text fontSize="sm" color="saur.greyTabs">
        {title}
      </Text>

      {link ? (
        <BreadcrumbLink as={RouterLink} to={link}>
          {labelField}
        </BreadcrumbLink>
      ) : (
        labelField
      )}
    </Flex>
  );
};

type Props = {
  pathList: Path[];
};
export const ResultPath = ({ pathList }: Props) => {
  const pathItems = pathList.map((item, index) => (
    <BreadcrumbItem key={index}>
      <ResultPathItem {...item} />
    </BreadcrumbItem>
  ));

  return (
    <Breadcrumb
      fontSize="sm"
      separator={<ChevronRightIcon boxSize={8} color="gray.300" />}
    >
      {pathItems}
    </Breadcrumb>
  );
};
