import { SearchResult } from './type';
import * as Helper from 'helpers/result';
import { TagWrapper } from 'components/common/Tag';
import { Link } from 'react-router-dom';
import { Box, Highlight, HStack, Text } from '@chakra-ui/react';
import { MatomoAction, MatomoCategory } from 'helpers/matomoTraking';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LocalStorage } from 'utils/localStorage';

type Props = {
  result: SearchResult;
  searchValue: string;
};

export const SearchBarOption = ({ result, searchValue }: Props) => {
  const { label, definition, dataType, dataNature, id } = result;
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  let route = '';
  let translation = '';
  let action: MatomoAction | undefined;

  if (Helper.isObject(dataType)) {
    route = 'object';
    translation = t('dataType.object');
    action = MatomoAction.clickObjectDetailsInSearchBar;
  } else if (Helper.isData(dataType)) {
    route = 'data';
    translation = t('dataType.data');
    action = MatomoAction.clickDataDetailsInSearchBar;
  } else if (Helper.isIndicator(dataType)) {
    route = 'indicator';
    translation = t('dataType.indicator');
    action = MatomoAction.clickIndicatorDetailsInSearchBar;
  }

  const handleClick = () => {
    trackEvent({
      category: MatomoCategory.searchBar,
      action: action ?? '',
      name: `${label} - ${translation}`,
    });

    LocalStorage.searchString.set(searchValue);
  };
  return (
    <Box
      as={Link}
      to={`/${route}/${id}`}
      aria-label="access details"
      bg="none"
      onClick={handleClick}
    >
      <HStack>
        <Text fontSize="md" noOfLines={1} maxW="3xl">
          <Highlight
            query={searchValue}
            styles={{
              px: '1',
              py: '1',
              paddingInlineEnd: 0,
              bg: 'saur.markHighlight',
            }}
          >
            {label}
          </Highlight>
        </Text>
        {Helper.isDefinition(dataNature) && (
          <>
            <Box as="span" fontSize="md">
              :
            </Box>
            <Text fontSize="md" noOfLines={1} w="25%">
              <Highlight
                query={searchValue}
                styles={{
                  px: '1',
                  py: '1',
                  paddingInlineEnd: 0,
                  bg: 'saur.markHighlight',
                }}
              >
                {definition}
              </Highlight>
            </Text>
          </>
        )}
        <TagWrapper dataType={dataType} />
      </HStack>
    </Box>
  );
};
