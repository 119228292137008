import { useState, useContext, createContext } from 'react';

export interface FeatureContexte {
  create: boolean;
}
type Props = {
  children: React.ReactNode;
};
// Initialize Context
const featureContext = createContext<FeatureContexte>({} as FeatureContexte);
// Provider component that wraps your app and makes Feature...
// ... available to any child component that calls useFeature().
export function FeatureFlipProvider({ children }: Props) {
  const feature = useProvideFeature();
  return (
    <featureContext.Provider value={feature}>
      {children}
    </featureContext.Provider>
  );
}
// Hook for child components to get Feature ...
// ... and re-render when it changes.
export const useFeatureFlip = () => {
  return useContext(featureContext);
};
// Provider hook that create and handle state
function useProvideFeature(): FeatureContexte {
  const [features] = useState<FeatureContexte>({
    create: false,
  });

  return features;
}
