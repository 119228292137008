import { useState, useContext, createContext } from 'react';

export interface KeycloakContexte {
  token: string;
  roles: string[];
  email: string;
  name: string;
  firstname: string;
  lastname: string;
  username: string;
}
type Props = {
  children: React.ReactNode;
  instance: any;
};
// Initialize Keycloak Context
const keycloakContext = createContext<KeycloakContexte>({} as KeycloakContexte);
// Provider component that wraps your app and makes Keycloak...
// ... available to any child component that calls useKeycloak().
export function KeycloakProvider({ children, instance }: Props) {
  const auth = useProvideKeycloak(instance);
  return (
    <keycloakContext.Provider value={auth}>{children}</keycloakContext.Provider>
  );
}
// Hook for child components to get Keycloak ...
// ... and re-render when it changes.
export const useKeycloak = () => {
  return useContext(keycloakContext);
};
// Provider hook that create and handle state
function useProvideKeycloak(keycloak: any): KeycloakContexte {
  const [keycloakData] = useState<KeycloakContexte>({
    token: keycloak.token,
    roles: keycloak.tokenParsed.realm_access.roles,
    email: keycloak.tokenParsed.email,
    name: keycloak.tokenParsed.name,
    firstname: keycloak.tokenParsed.given_name,
    lastname: keycloak.tokenParsed.family_name,
    username: keycloak.tokenParsed.preferred_username,
  });

  // Return the keycloak data
  return keycloakData;
}
