import { Quality } from './type';
import {
  HStack,
  SimpleGrid,
  Text,
  Flex,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react';
import { DataType } from 'helpers/result';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QualityGraph } from 'assets/quality-graph.svg';
import { round } from 'utils/round';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { WrapTooltip } from 'components/common/WrapTooltip';

type Props = {
  quality: Quality;
  dataType: DataType;
};

export const QualityTab = ({ quality, dataType }: Props) => {
  const { t } = useTranslation();

  let averageTitle = '';
  let noData = '';

  if (dataType === DataType.Object) {
    averageTitle = t('itemTab.qualityTab.averageTitle.object');
    noData = t('itemTab.qualityTab.noData.object');
  } else if (dataType === DataType.Data) {
    averageTitle = t('itemTab.qualityTab.averageTitle.data');
    noData = t('itemTab.qualityTab.noData.data');
  } else if (dataType === DataType.Indicator) {
    averageTitle = t('itemTab.qualityTab.averageTitle.indicator');
    noData = t('itemTab.qualityTab.noData.indicator');
  }

  if (quality.qualityResults?.length === 0) {
    return <div>{noData}</div>;
  }

  const { qualityResults, averageResult } = quality;
  const displayAverage = round(averageResult, 2);
  const displayAverageSplit = displayAverage.toString().split('.');
  const diplayAverageIntPart = displayAverageSplit[0];
  const displayAverageDecimalPart = displayAverageSplit[1];

  const results = qualityResults.map((quality, index) => {
    const { type, result, targetMin, target, targetMax, description } = quality;
    const displayResult = round(result, 2);
    const targetArray = [targetMin, target, targetMax];

    let bgFilledTrack = 'gray.500';

    if (targetMax && displayResult <= targetMax) {
      bgFilledTrack = 'saur.slider.high';
    }
    if (targetMin && displayResult < targetMin) {
      bgFilledTrack = 'saur.slider.low';
    } else if (targetMin && displayResult >= targetMin) {
      bgFilledTrack = 'saur.slider.medium';
    } else if (target && displayResult < target) {
      bgFilledTrack = 'saur.slider.medium';
    }
    if (target && displayResult >= target) {
      bgFilledTrack = 'saur.slider.high';
    }
    if (targetMax && displayResult > targetMax) {
      bgFilledTrack = 'saur.slider.low';
    }

    let title = '';
    switch (type.toLowerCase()) {
      case 'complétude':
        title = t('itemTab.qualityTab.title.completeness');
        break;
      case 'intégrité':
        title = t('itemTab.qualityTab.title.integrity');
        break;
      case 'exactitude':
        title = t('itemTab.qualityTab.title.accuracy');
        break;
      case 'disponibilité':
        title = t('itemTab.qualityTab.title.availability');
        break;
      case 'cohérence':
        title = t('itemTab.qualityTab.title.consistency');
        break;
      case 'conformité':
        title = t('itemTab.qualityTab.title.compliance');
        break;
      case 'exhaustivité':
        title = t('itemTab.qualityTab.title.exhaustiveness');
        break;
      case 'unicité':
        title = t('itemTab.qualityTab.title.unicity');
        break;
      case 'anonymisation':
        title = t('itemTab.qualityTab.title.anonymization');
        break;
      case 'fraîcheur':
        title = t('itemTab.qualityTab.title.freshness');
        break;
      case 'traçabilité':
        title = t('itemTab.qualityTab.title.traceability');
        break;
      case 'accessibilité':
        title = t('itemTab.qualityTab.title.accessibility');
        break;
      default:
        title = type;
        break;
    }

    return (
      <Flex key={index} flexDir="column" gap={2.5}>
        <HStack>
          <Flex fontSize="sm" color="saur.greyTabs" gap={2}>
            <Text>{title} :</Text>
            <Text>{displayResult} %</Text>
          </Flex>
          {description && (
            <WrapTooltip hasArrow label={description}>
              <InfoOutlineIcon color="saur.blue" boxSize={4} />
            </WrapTooltip>
          )}
        </HStack>
        <Slider
          id={`slider-${type}`}
          aria-label={type}
          value={displayResult}
          isReadOnly
        >
          {targetArray.map((target, index) =>
            target ? (
              <SliderMark
                key={`slider-mark-${type}-${index}`}
                id={`slider-mark-${type}-${index}`}
                value={target}
                mt="-1"
                ml="-0.5"
                zIndex="toast"
              >
                <Box
                  bg="white"
                  border="2px solid lightgrey"
                  rounded="full"
                  boxSize={2}
                />
              </SliderMark>
            ) : null,
          )}
          <SliderTrack bg="saur.slider.track">
            <SliderFilledTrack bg={bgFilledTrack} />
          </SliderTrack>
          <SliderThumb bg={bgFilledTrack} zIndex="tooltip" />
        </Slider>
      </Flex>
    );
  });

  return (
    <Flex gap={28} padding={4}>
      <SimpleGrid columns={2} spacingX={8} spacingY={6} w="55%">
        {results}
      </SimpleGrid>
      <HStack spacing={10}>
        <QualityGraph />
        <Flex flexDir="column">
          <Text color="saur.greyTabs" fontSize="sm">
            {averageTitle}
          </Text>
          <Flex align="self-end">
            <Text fontSize="6xl">{diplayAverageIntPart}</Text>
            {displayAverageDecimalPart ? (
              <Text fontSize="3xl" mb={3}>
                .{displayAverageDecimalPart}
              </Text>
            ) : null}
            <Text fontSize="md" mb={5}>
              /100
            </Text>
          </Flex>
        </Flex>
      </HStack>
    </Flex>
  );
};
