import { HStack } from '@chakra-ui/react';
import { StatCardItem } from './StatCardItem';
import { StatCard } from './types';

export type Props = {
  list: StatCard[];
};
export const StatCardList = ({ list }: Props) => {
  const listWrapped = list.map((item) => (
    <StatCardItem key={item.id} {...item} />
  ));
  return <HStack spacing={4}>{listWrapped}</HStack>;
};
