import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { styles } from './styles';

export const theme = extendTheme({
  styles: styles,
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
    mono: 'Montserrat',
  },
  colors: {
    grey: baseTheme.colors.gray,
    saur: {
      black: '#232323',
      lightBlack: '#323232',
      markHighlight: '#fde68a',
      object: '#008bd2',
      data: '#243977',
      indicator: '#b8cce8',
      available: '#bbe8cd',
      unavailable: '#d9c3ff',
      undefined: '#e9e9e9',
      domain: {
        customers: '#b8cce8',
        operations: '#ffe0b3',
        transverseFunctions: '#d9c3ff',
        indicators: '#CCCCCC',
      },
      blueCheckbox: { 500: '#1157B2' },
      greyCheckbox: { 500: '#939393' },
      green: { 500: '#63d1b4', 600: '#63d1b4' },
      greyTable: { 100: '#ECF1F8' },
      greyTabs: '#5D5D5D',
      blue: '#008bd2',
      switch: {
        disabled: '#c1c1c1',
      },
      slider: {
        track: '#e9e9e9',
        high: '#BBE8CD',
        medium: '#FFE0B3',
        low: '#FE3123',
        filled: '#d9c3ff',
      },
      importResult: {
        error: '#FE3123',
        warning: '#E6AB54',
      },
      stat: { result: '#243977', bg: '#ECF1F8' },
    },
  },
});
