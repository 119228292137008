export const LocalStorage = {
  token: {
    get: () => localStorage.getItem('token'),
    set: (value: string) => localStorage.setItem('token', value),
  },
  searchString: {
    get: () => localStorage.getItem('searchString'),
    set: (value: string) => localStorage.setItem('searchString', value),
  },
};
