import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoCategory, MatomoActionDynamic } from 'helpers/matomoTraking';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { capitalize } from 'utils/capitalize';
import { languages } from 'config/languages';
import { useStore } from 'store';

export const HeaderLangage = () => {
  const { i18n } = useTranslation();
  const { trackEvent } = useMatomo();
  const index = useStore((state) => state.languageIndex);
  const changeLanguage = useStore((state) => state.changeLanguage);

  const onSelect = (code: string) => {
    i18n.changeLanguage(code);

    trackEvent({
      category: MatomoCategory.language,
      action: MatomoActionDynamic.changeLangTo(code),
    });
  };

  const languagesList = languages.map((lang) => (
    <Tab
      key={`tabs-${lang}`}
      id={`tabs-${lang}`}
      onClick={() => onSelect(lang)}
      color="saur.black"
      bg="none"
      _selected={{ color: 'white', bg: 'saur.blue' }}
      rounded="full"
      fontWeight="bold"
      fontSize="sm"
    >
      {capitalize(lang)}
    </Tab>
  ));

  return (
    <Tabs
      variant="unstyled"
      index={index}
      onChange={(index) => changeLanguage(index)}
    >
      <TabList bg="rgba(0, 139, 210, 0.5)" rounded="full" w="max">
        {languagesList}
      </TabList>
    </Tabs>
  );
};
