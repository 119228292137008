import { useRef } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { SearchResult } from './type';
import { ResultPath, Path } from './ResultPath';
import * as Helper from 'helpers/result';
import { TagWrapper, TagWrapperDispo } from 'components/common/Tag';
import { Link as RouterLink } from 'react-router-dom';
import { GridPanelItem, DataTabs, DataRow } from 'components/GridPanel';
import { SwitchToggleYesNo } from 'components/common/SwitchToggle';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoCategory, MatomoAction } from 'helpers/matomoTraking';
import {
  chakra,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  Link,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { useOverflow } from 'utils/hooks/useOverflow';
import { WrapTooltip } from 'components/common/WrapTooltip';
import {
  usePrefetchObject,
  usePrefetchData,
  usePrefetchIndicator,
} from './hooks';

type Props = {
  data: SearchResult;
};

export const SearchResultItem = ({ data }: Props) => {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  const objectType = Helper.isObject(data.dataType);
  const dataType = Helper.isData(data.dataType);
  const indicatorType = Helper.isIndicator(data.dataType);
  const headingRef = useRef(null);
  const overflow = useOverflow(headingRef);
  const { prefetch: prefetchObject } = usePrefetchObject(data.id);
  const { prefetch: prefetchData } = usePrefetchData(data.id);
  const { prefetch: prefetchIndicator } = usePrefetchIndicator(data.id);

  const searchPath: Path[] = [
    {
      title: t('searchResultPath.domain'),
      label: data.domain,
    },
    {
      title: t('searchResultPath.subdomain'),
      label: data.subDomain,
    },
  ];
  if (objectType) {
    searchPath.push({
      title: t('searchResultPath.businessObject'),
      label: data.label,
    });
  }
  if (dataType || indicatorType) {
    searchPath.push({
      title: t('searchResultPath.businessObject'),
      label: data.link?.label as string,
      link: `/object/${data.link?.id}`,
    });
  }
  if (dataType) {
    searchPath.push({
      title: t('searchResultPath.dataName'),
      label: data.label,
    });
  }
  if (indicatorType) {
    searchPath.push({
      title: t('searchResultPath.indicatorName'),
      label: data.label,
    });
  }
  if (Helper.isDefinition(data.dataNature)) {
    searchPath.push({
      title: t('searchResultPath.definition'),
      label: data.definition,
    });
  }

  const businessTab: DataRow[] = [
    [
      {
        title: objectType
          ? t('itemTab.businessTab.dataManager')
          : t('itemTab.businessTab.contributor'),
        content: objectType ? data.dataManager : data.contributor,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataOwner'),
        content: data.productionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataCustodian'),
        content: data.provisionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
    ],
    [
      {
        title: objectType
          ? t('itemTab.dataPlatformTab.coreObjectModeled')
          : t('itemTab.dataPlatformTab.coreDataModeled'),
        content: <SwitchToggleYesNo data={data.modeledData} />,
      },
      {
        title: t('itemTab.dataPlatformTab.dataPlateformAvailability'),
        content: <TagWrapperDispo data={data.availableData} />,
      },
    ],
  ];

  const details: DataTabs = {
    label: 'details',
    value: 'details',
    panel: businessTab,
  };

  let route = '';
  let translation = '';
  let actionDetails: MatomoAction | undefined;
  let actionExpand: MatomoAction | undefined;
  let prefetch: () => Promise<void> = () => Promise.resolve();

  if (objectType) {
    route = 'object';
    translation = t('dataType.object');
    actionDetails = MatomoAction.clickObjectDetailsInSearchResultPage;
    actionExpand = MatomoAction.clickObjectExpandMoreInfoInSearchResultPage;
    prefetch = prefetchObject;
  } else if (dataType) {
    route = 'data';
    translation = t('dataType.data');
    actionDetails = MatomoAction.clickDataDetailsInSearchResultPage;
    actionExpand = MatomoAction.clickDataExpandMoreInfoInSearchResultPage;
    prefetch = prefetchData;
  } else if (indicatorType) {
    route = 'indicator';
    translation = t('dataType.indicator');
    actionDetails = MatomoAction.clickIndicatorDetailsInSearchResultPage;
    actionExpand = MatomoAction.clickIndicatorExpandMoreInfoInSearchResultPage;
    prefetch = prefetchIndicator;
  }

  const detailsRoute = `/${route}/${data.id}`;

  const handleClickDetailsPage = () => {
    trackEvent({
      category: MatomoCategory.searchResultPage,
      action: actionDetails ?? '',
      name: `${data.label} - ${translation}`,
    });
  };

  const handleClickExpandMoreInfo = () => {
    trackEvent({
      category: MatomoCategory.searchResultPage,
      action: actionExpand ?? '',
      name: `${data.label} - ${translation}`,
    });
  };

  return (
    <Box
      id={`search-result-${data.id}`}
      className="search-result"
      bg="white"
      borderRadius="2xl"
      w="full"
      boxShadow="0 0.875rem 0.875rem 0.188rem rgba(0, 0, 0, 0.05)"
    >
      <Flex>
        <HStack spacing={2} ml={5}>
          <WrapTooltip isDisabled={!overflow} label={data.label.toUpperCase()}>
            <Link
              as={RouterLink}
              to={detailsRoute}
              aria-label="access details"
              onClick={handleClickDetailsPage}
              onMouseEnter={prefetch}
            >
              <Heading
                ref={headingRef}
                noOfLines={1}
                fontSize="md"
                maxW="3xl"
                id={`title-${data.id}`}
                className="search-result-title"
              >
                {data.label.toUpperCase()}
              </Heading>
            </Link>
          </WrapTooltip>
          <TagWrapper dataType={data.dataType} />
        </HStack>
        <Spacer />
        <Link
          as={RouterLink}
          to={detailsRoute}
          aria-label="access details"
          bg="saur.green.500"
          roundedTopRight="2xl"
          px={4}
          py={6}
          onClick={handleClickDetailsPage}
          onMouseEnter={prefetch}
        >
          <ViewIcon boxSize={5} />
        </Link>
      </Flex>
      <Accordion allowMultiple borderColor="white">
        <AccordionItem borderBottomWidth="0 !important">
          {({ isExpanded }) => (
            <>
              <Flex>
                <Box flex="1" textAlign="left" ml={5} pb={8}>
                  <ResultPath pathList={searchPath} />
                </Box>
                <chakra.h2
                  bg="saur.green.500"
                  roundedBottomRight={isExpanded ? 'none' : '2xl'}
                >
                  <AccordionButton h="full" onClick={handleClickExpandMoreInfo}>
                    <AccordionIcon boxSize={5} />
                  </AccordionButton>
                </chakra.h2>
              </Flex>
              <AccordionPanel p={0}>
                <Flex>
                  <Box ml={5} w="full">
                    <Box>
                      <Heading
                        as="h3"
                        fontSize="sm"
                        color="saur.greyTabs"
                        fontWeight="medium"
                      >
                        {t('definition')}
                      </Heading>
                      <Text fontSize="sm">{data.definition || '-'}</Text>
                    </Box>
                    <GridPanelItem
                      item={details}
                      decoration={false}
                      delimitation={false}
                    />
                  </Box>
                  <Box
                    w="3.556rem"
                    bg="saur.green.500"
                    roundedBottomRight="2xl"
                    ml={4}
                  />
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
