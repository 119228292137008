import { Tooltip, TooltipProps } from '@chakra-ui/react';

export const WrapTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      fontSize="sm"
      placement="top"
      bg="saur.blue"
      color="white"
      rounded="3xl"
      textAlign="center"
      {...props}
    >
      {children}
    </Tooltip>
  );
};
