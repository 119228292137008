import { useState, useEffect } from 'react';

function isOverflowActive(e: HTMLElement | null) {
  if (!e) return false;
  return e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight;
}
export const useOverflow = (ref: React.RefObject<HTMLElement>) => {
  const [overflow, setOverflow] = useState(false);
  useEffect(() => {
    if (!ref.current) return;
    setOverflow(isOverflowActive(ref.current));
  }, [ref]);
  return overflow;
};
