import { useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useAuthorizationAdmin } from 'modules/auth';
import { useTranslation } from 'react-i18next';
import { useStore } from 'store';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { HeaderLangage } from './HeaderLangage';
import { HeaderNavItem } from './HeaderNavItem';
import { MenuConfig } from './types';

export const HeaderProfilMenu = () => {
  const { t } = useTranslation();
  const profilMenu = useStore((state) => state.profilMenu);
  const toggleProfilMenu = useStore((state) => state.toggleProfilMenu);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, toggleProfilMenu);
  const { authorize: authAdmin } = useAuthorizationAdmin();
  const menu: MenuConfig[] = [
    {
      to: '/admin',
      name: t('header.admin'),
      visible: authAdmin,
    },
  ];

  return (
    <>
      {profilMenu && (
        <Box
          ref={menuRef}
          bg="#1156B2"
          color="white"
          roundedBottomLeft="3xl"
          pl={8}
          pr={28}
          pt={28}
          pb={4}
          position="absolute"
          zIndex="dropdown"
          top={0}
          right={0}
        >
          <Flex direction="column">
            <Box mb={2.5}>
              <Text mb={2} fontSize="sm">
                {t('languages')}
              </Text>
              <HeaderLangage />
            </Box>

            <Flex direction="column" alignItems="start">
              {menu.map(
                ({ to, name, visible }) =>
                  visible && (
                    <HeaderNavItem key={to} to={to}>
                      {name}
                    </HeaderNavItem>
                  ),
              )}
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};
