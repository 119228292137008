import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Box,
  HStack,
  Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseReport, ReportType } from './type';

type Props = {
  reports: BaseReport[];
  tableType: ReportType;
};
export const ImportReportTable = ({ reports, tableType }: Props) => {
  const { t } = useTranslation();
  const [showReport, setShowReport] = useState(reports);

  const tableHeader = t('import.reportTableHeader', {
    returnObjects: true,
  }) as string[];

  const title =
    tableType === 'Error' ? t('import.reportError') : t('import.reportWarning');
  const color =
    tableType === 'Error'
      ? 'saur.importResult.error'
      : 'saur.importResult.warning';

  const filterOption = Array.from(
    new Set(reports.map((report) => report.elementType)),
  );

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      setShowReport(reports);
    } else {
      setShowReport(
        reports.filter((report) => report.elementType === e.target.value),
      );
    }
  };

  return (
    <>
      <Heading as="h4" fontSize="lg" textAlign="left">
        <HStack spacing={2}>
          <Box as="span" color={color}>
            {reports.length}
          </Box>
          <Box as="span">{title}</Box>
        </HStack>
      </Heading>
      <Select placeholder={t('all')} onChange={handleFilterChange}>
        {filterOption.map((option, index) => (
          <option key={option + index} value={option}>
            {option}
          </option>
        ))}
      </Select>
      <Box h="2xs" overflowY="scroll" scrollBehavior="smooth">
        <TableContainer>
          <Table variant="striped" colorScheme="saur.greyTable" fontSize="sm">
            <Thead>
              <Tr>
                {tableHeader.map((header) => (
                  <Th>{header}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {showReport.map((report) => (
                <Tr key={report.idImportReport}>
                  <Td>{report.elementLine}</Td>
                  <Td>{report.elementId}</Td>
                  <Td>{report.elementType}</Td>
                  <Td wordBreak="break-word" whiteSpace="normal">
                    {report.elementLabel}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
