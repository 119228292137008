import { useQuery, useQueryClient } from '@tanstack/react-query';
import { uploadImportFile, validateImport } from './api';

export function useImportResult(file: File, { enabled = false } = {}) {
  return useQuery(['importresult'], () => uploadImportFile(file), {
    enabled: enabled,
    staleTime: 900000,
  });
}

export function useImportValidate({ enabled = false } = {}) {
  return useQuery(['importvalidate'], validateImport, {
    enabled: enabled,
    staleTime: 900000,
  });
}

export function useRemoveImportResult() {
  const queryClient = useQueryClient();

  const remove = () => {
    queryClient.removeQueries(['importresult'], { exact: true });
  };

  return { remove };
}
