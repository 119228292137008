import ObjectLogo from 'assets/create/object.png';
import DataLogo from 'assets/create/data.png';
import IndicatorLogo from 'assets/create/indicator.png';
import { useTranslation } from 'react-i18next';
import { BasePageContainer } from 'components/common/Page';
import { MenuCardList, MenuCard } from 'components/MenuCard';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

export const HomeCreatePage = () => {
  const { t } = useTranslation();
  const cards: MenuCard[] = [
    {
      id: 'object',
      title: t('create.menu.object.title'),
      description: t('create.menu.object.description'),
      logo: ObjectLogo,
      action: t('create.menu.object.action'),
    },
    {
      id: 'data',
      title: t('create.menu.data.title'),
      description: t('create.menu.data.description'),
      logo: DataLogo,
      action: t('create.menu.data.action'),
    },
    {
      id: 'indicator',
      title: t('create.menu.indicator.title'),
      description: t('create.menu.indicator.description'),
      logo: IndicatorLogo,
      action: t('create.menu.indicator.action'),
    },
  ];
  return (
    <BasePageContainer title={t('create.title')}>
      <ResponsiveBox>
        <MenuCardList list={cards} />
      </ResponsiveBox>
    </BasePageContainer>
  );
};
