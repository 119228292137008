import { useState, useEffect } from 'react';
import { SearchResult, SearchResultList } from 'features/search';
import { DataType } from 'helpers/result';
import { useNavigate } from 'react-router-dom';
import { SearchBar, useSearch } from 'features/search';
import { useTranslation } from 'react-i18next';
import { useQueryUrl } from 'utils/hooks/navigation';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoCategory, MatomoAction } from 'helpers/matomoTraking';
import { LocalStorage } from 'utils/localStorage';
import {
  FilterButtonList,
  EnumButtonVariant,
  FilterButton,
} from 'components/FilterButton';
import { Flex, VStack } from '@chakra-ui/react';
import { LoadingSpinner, NoResult, Error } from 'components/layout';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

export const SearchResultPage = () => {
  const [value, setValue] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQueryUrl();
  const search = query.get('searchstring');
  const { trackEvent, trackSiteSearch } = useMatomo();
  const [searchResultFiltered, setSearchResultFiltered] = useState<
    SearchResult[]
  >([]);
  const { isLoading, isFetching, isError, error, data } = useSearch(search);
  useEffect(() => {
    if (data && !isLoading) {
      setSearchResultFiltered(data);
      setValue(1);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (search) {
      console.log('SearchResult Page : get from params', search);
      LocalStorage.searchString.set(search);
    } else {
      navigate('/');
    }
  }, [navigate, search]);

  // Track page view
  useEffect(() => {
    if (isLoading || isFetching || !search) {
      return;
    }
    const searchCount = data?.length || 0;

    trackSiteSearch({
      documentTitle: document.title,
      href: document.URL,
      keyword: search,
      category: MatomoCategory.searchResultPage,
      count: searchCount,
    });
  }, [isLoading, isFetching, data, search, trackSiteSearch]);

  const filterList: FilterButton[] = [
    {
      value: 1,
      label: t('all'),
      matomoAction: MatomoAction.clickFilterAllInSearchResultPage,
    },
    {
      value: 2,
      label: t('dataType.objects'),
      variant: EnumButtonVariant.OBJECT,
      matomoAction: MatomoAction.clickFilterObjectInSearchResultPage,
      filterType: DataType.Object,
    },
    {
      value: 3,
      label: t('dataType.datas'),
      variant: EnumButtonVariant.DATA,
      matomoAction: MatomoAction.clickFilterDataInSearchResultPage,
      filterType: DataType.Data,
    },
    {
      value: 4,
      label: t('dataType.indicators'),
      variant: EnumButtonVariant.INDICATOR,
      matomoAction: MatomoAction.clickFilterIndicatorInSearchResultPage,
      filterType: DataType.Indicator,
    },
  ];

  const handleChange = (newValue: number) => {
    setValue(newValue);
    const button = filterList.find((fb) => fb.value === newValue);
    const searchResult = data || [];

    if (!button) {
      return;
    }

    const filterResult =
      button.value === 1
        ? searchResult
        : searchResult.filter(
            (result) => result.dataType === button.filterType,
          );
    setSearchResultFiltered(filterResult);

    trackEvent({
      category: MatomoCategory.searchResultPage,
      action: button.matomoAction,
      name: `${button.label}`,
    });
  };

  return (
    <VStack>
      <ResponsiveBox gap={5} mb={8}>
        <SearchBar searchValue={search ?? ''} />
        {isLoading ? (
          <LoadingSpinner />
        ) : isError ? (
          <Error error={error as Error} />
        ) : (
          <Flex flexDir="column" gap={5}>
            <FilterButtonList
              currentValue={value}
              list={filterList}
              onClick={handleChange}
            />
            {data && searchResultFiltered.length > 0 ? (
              <SearchResultList list={searchResultFiltered} />
            ) : (
              <NoResult />
            )}
          </Flex>
        )}
      </ResponsiveBox>
    </VStack>
  );
};
