function includeDomain(domains: string[], domain: string) {
  return domains.includes(domain);
}

export function isCustomers(domain: string) {
  const array = ['customers', 'clientèle'];
  return includeDomain(array, domain.toLowerCase());
}

export function isOperations(domain: string) {
  const array = ['operations', 'exploitation'];
  return includeDomain(array, domain.toLowerCase());
}

export function isTransverseFunctions(domain: string) {
  const array = ['transverse functions', 'fonctions transverses'];
  return includeDomain(array, domain.toLowerCase());
}

export function isIndicators(domain: string) {
  const array = ['indicators', 'indicateurs'];
  return includeDomain(array, domain.toLowerCase());
}
