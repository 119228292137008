type Env = {
  isLocal: boolean;
  baseUrl: string;
  baseUrlIntern: string;
  keycloak: {
    realm: string;
    clientId: string;
    url: string;
  };
  matomo: {
    url: string;
    siteId: number;
  };
};
const local = process.env.REACT_APP_LOCAL === 'true';
export const env: Env = {
  isLocal: local,
  baseUrlIntern:
    (local
      ? process.env.REACT_APP_API_BASE_URL_LOCAL
      : process.env.REACT_APP_API_BASE_URL_INTERN) || '',
  baseUrl:
    (local
      ? process.env.REACT_APP_API_BASE_URL_LOCAL
      : process.env.REACT_APP_API_BASE_URL) || '',
  keycloak: {
    url: process.env.REACT_APP_URL_KEYCLOAK || '',
    clientId: process.env.REACT_APP_CLIENTID_KEYCLOAK || '',
    realm: process.env.REACT_APP_REALM_KEYCLOAK || '',
  },
  matomo: {
    url: process.env.REACT_APP_MATOMO_URL as string,
    siteId: process.env.REACT_APP_MATOMO_ID as unknown as number,
  },
};
