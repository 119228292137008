import { Grid } from '@chakra-ui/react';
import { Children } from 'react';

type Props = {
  columns?: number;
  gridGap?: number;
  isProvideLi?: boolean;
  children: React.ReactNode;
};

export const Masonry = ({
  columns = 3,
  gridGap = 4,
  isProvideLi = true,
  children,
}: Props) => {
  // split children into N arrays for columns
  const output = Children.toArray(children).reduce((acc, child, i) => {
    acc[i % columns] = [
      ...acc[i % columns],
      isProvideLi ? <div key={`masonry-column-${i}`}>{child}</div> : child,
    ];
    return acc;
  }, new Array(columns).fill([]));

  return (
    <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={gridGap}>
      {output.map((column, i) => (
        <Grid
          key={`masonry-element-${i}`}
          gridGap={gridGap}
          style={{ gridAutoRows: 'max-content' }}
        >
          {column}
        </Grid>
      ))}
    </Grid>
  );
};
