import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'modules/i18n';
import { env } from 'config/environment';
import { LocalStorage } from 'utils/localStorage';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

// Init Keycloak
declare var Keycloak: any;

const keycloak = new Keycloak({
  url: env.keycloak.url,
  clientId: env.keycloak.clientId,
  realm: env.keycloak.realm,
});
const tokenCallback = () => {
  console.log('Token callback triggered');
  LocalStorage.token.set(keycloak.token);
};
const update = () => {
  keycloak
    .updateToken(50)
    .then(() => {
      console.log(`Token refreshed at ${new Date()}`);
      tokenCallback();
    })
    .catch(() => {
      console.warn(
        'Token not refreshed, valid for ' +
          Math.round(
            keycloak.tokenParsed.exp +
              keycloak.timeSkew -
              new Date().getTime() / 1000,
          ) +
          ' seconds',
      );
      console.error(`Failed to refresh token ${new Date()}`);
    });
};
keycloak.onTokenExpired = () => {
  console.log(`expired ${new Date()}`);
  update();
};
keycloak
  .init({ onLoad: 'login-required' })
  .then((authenticated: boolean) => {
    if (authenticated) {
      LocalStorage.token.set(keycloak.token);
      root.render(
        <StrictMode>
          <App keycloak={keycloak} />
        </StrictMode>,
      );
    } else {
      window.location.reload();
    }
  })
  .catch(() => {
    console.error('Authentication Failed');
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
