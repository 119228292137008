import { ButtonProps, chakra, Flex } from '@chakra-ui/react';
import { ButtonVariant, EnumButtonVariant, FilterButton } from './types';

type FilterButtonProps = {
  variant?: ButtonVariant;
  selected: boolean;
};
const ButtonElement = ({
  children,
  variant,
  selected,
  ...rest
}: FilterButtonProps & ButtonProps) => {
  let bgColor = 'saur.lightBlack';
  let color = 'white';

  if (variant === EnumButtonVariant.OBJECT) {
    bgColor = 'saur.object';
  } else if (variant === EnumButtonVariant.DATA) {
    bgColor = 'saur.data';
  } else if (variant === EnumButtonVariant.INDICATOR) {
    bgColor = 'saur.indicator';
    color = 'saur.black';
  } else if (variant === EnumButtonVariant.CUSTOMERS) {
    bgColor = 'saur.domain.customers';
    color = 'saur.black';
  } else if (variant === EnumButtonVariant.OPERATIONS) {
    bgColor = 'saur.domain.operations';
    color = 'saur.black';
  } else if (variant === EnumButtonVariant.TRANSVERSE_FUNCTIONS) {
    bgColor = 'saur.domain.transverseFunctions';
    color = 'saur.black';
  } else if (variant === EnumButtonVariant.INDICATORS) {
    bgColor = 'saur.domain.indicators';
    color = 'saur.black';
  }

  return (
    <chakra.button
      type="button"
      bgColor={selected ? bgColor : undefined}
      color={selected ? color : 'saur.black'}
      fontSize="sm"
      fontWeight="bold"
      borderRadius="full"
      py={2}
      px={5}
      {...rest}
    >
      {children}
    </chakra.button>
  );
};

type Props = {
  currentValue: number;
  list: FilterButton[];
  onClick: (newValue: number) => void;
};
export const FilterButtonList = ({ currentValue, list, onClick }: Props) => {
  return (
    <Flex
      direction="row"
      bgColor="rgba(184, 204, 232, 0.2)" //bg color base : #b8cce8 with opacity 0.2
      borderRadius="full"
      w="max"
    >
      {list.map(({ value, label, element, variant }) => {
        return (
          <ButtonElement
            id={`button-filter-${value}`}
            key={`button-filter-${value}`}
            onClick={() => onClick(value)}
            selected={value === currentValue}
            variant={variant}
          >
            {element ? element : label}
          </ButtonElement>
        );
      })}
    </Flex>
  );
};
