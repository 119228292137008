import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LegendPanel } from './LegendPanel';
import { Box, Heading, HStack, VStack } from '@chakra-ui/react';
import { LoadingSpinner } from 'components/layout';

type Props = {
  children?: React.ReactNode;
  title?: string;
  loading?: boolean;
  marginTop?: boolean;
  legendContent?: React.ReactNode;
};

export const BasePageContainer = ({
  title,
  loading,
  children,
  marginTop,
  legendContent,
}: Props) => {
  const { trackPageView } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <VStack spacing={8} mt={marginTop ? 40 : 10}>
      <HStack spacing={2}>
        {title ? (
          <Heading
            as="h1"
            fontSize="1.75rem"
            textAlign="center"
            fontWeight="normal"
          >
            {title}
          </Heading>
        ) : null}
        {legendContent && !loading ? (
          <LegendPanel>{legendContent}</LegendPanel>
        ) : null}
      </HStack>
      {loading ? <LoadingSpinner /> : <Box>{children}</Box>}
    </VStack>
  );
};
