import { BasePageContainer } from 'components/common/Page';
import { useTranslation } from 'react-i18next';
import {
  Heading,
  Image,
  VStack,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Spacer,
  HStack,
} from '@chakra-ui/react';
import ObjectLogo from 'assets/create/object.png';
import DataLogo from 'assets/create/data.png';
import IndicatorLogo from 'assets/create/indicator.png';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

type Question = {
  title: string;
  text: string;
  icon?: string;
};
type FaqQuestion = Question & {
  subQuestions?: Question[];
};

export const FaqPage = () => {
  const { t } = useTranslation();
  const questions = t('faq.questions', {
    returnObjects: true,
  }) as FaqQuestion[];

  // function to select the correct icon
  const getImage = (title: string) => {
    let logo = '';
    switch (title) {
      case 'object':
        logo = ObjectLogo;
        break;
      case 'data':
        logo = DataLogo;
        break;
      case 'indicator':
        logo = IndicatorLogo;
        break;
      default:
        logo = '';
        break;
    }
    return logo;
  };

  const accordionItems = questions.map((question, index) => {
    const { title, text, subQuestions, icon } = question;

    // subquestions mapping
    const subQuestionsItems = subQuestions?.map((subQuestion, subIndex) => {
      const { title: subTitle, text: subText, icon: subIcon } = subQuestion;
      return (
        <AccordionItem key={`${index}-${subIndex}`}>
          <AccordionButton>
            <Flex w="full">
              <Heading as="h3" fontSize="md" textAlign="left">
                {subTitle}
              </Heading>
              <Spacer />
              <AccordionIcon />
            </Flex>
          </AccordionButton>
          <AccordionPanel>
            {subIcon || subText ? (
              <HStack spacing={4}>
                {subIcon ? (
                  <Image
                    id={`faq-img-${subTitle}`}
                    alt={subTitle}
                    src={getImage(subIcon)}
                    boxSize={20}
                  />
                ) : null}
                {subText ? (
                  <Text fontSize="sm" color="saur.lightBlack">
                    {subText}
                  </Text>
                ) : null}
              </HStack>
            ) : null}
          </AccordionPanel>
        </AccordionItem>
      );
    });

    // main question mapping
    return (
      <AccordionItem
        key={index}
        bg="white"
        borderRadius="2xl"
        boxShadow="0 0.875rem 0.875rem 0.188rem rgba(0, 0, 0, 0.05)"
        borderBottomWidth="0 !important"
        borderTopWidth="0 !important"
      >
        <AccordionButton h="3.75rem">
          <Flex w="full">
            <Heading as="h2" fontSize="md" textAlign="left">
              {title}
            </Heading>
            <Spacer />
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel display="flex" flexDir="column" gap={4}>
          {icon || text ? (
            <HStack spacing={4}>
              {icon ? (
                <Image
                  id={`faq-img-${title}`}
                  alt={title}
                  src={getImage(icon)}
                  boxSize={20}
                />
              ) : null}
              {text ? (
                <Text fontSize="sm" color="saur.lightBlack">
                  {text}
                </Text>
              ) : null}
            </HStack>
          ) : null}
          <div>{subQuestionsItems}</div>
        </AccordionPanel>
      </AccordionItem>
    );
  });

  return (
    <BasePageContainer title={t('faq.title')}>
      <ResponsiveBox>
        <VStack spacing={8} pb={8}>
          <VStack
            spacing={5}
            bg="white"
            borderRadius="2xl"
            px={10}
            py={5}
            boxShadow="0 0.875rem 0.875rem 0.188rem rgba(0, 0, 0, 0.05)"
          >
            <Heading as="h2" fontSize="md" textAlign="center">
              {t('faq.intro.title')}
            </Heading>
            <Text fontSize="sm" color="saur.lightBlack">
              {t('faq.intro.text')}
            </Text>
          </VStack>

          <Accordion
            allowMultiple
            w="full"
            display="flex"
            gap={8}
            flexDir="column"
          >
            {accordionItems}
          </Accordion>
        </VStack>
      </ResponsiveBox>
    </BasePageContainer>
  );
};
