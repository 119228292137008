import { http } from 'utils/http';
import { SearchResult, Object, Data, Indicator } from './type';
import { AxiosError } from 'axios';

export const getSearch = async (search: string, lang: string) => {
  try {
    const { data } = await http.get<SearchResult[]>(
      `/Search?searchString=${search}&language=${lang}`,
    );

    return data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 404) {
      return [];
    }
    throw e;
  }
};

export const getObject = async (objectId: number, lang: string) => {
  const { data } = await http.get<Object>(
    `/Object/${objectId}?language=${lang}`,
  );
  return data;
};

export const getData = async (dataId: number, lang: string) => {
  const { data } = await http.get<Data>(`/Data/${dataId}?language=${lang}`);

  return data;
};

export const getIndicator = async (indicatorId: number, lang: string) => {
  const { data } = await http.get<Indicator>(
    `/Indicator/${indicatorId}?language=${lang}`,
  );

  return data;
};
