import { useState, useEffect } from 'react';
import { CustomSwitch } from './CustomSwitch';
import { useTranslation } from 'react-i18next';
import { Box, HStack } from '@chakra-ui/react';

export const SwitchToggle = ({
  data,
  textLeft,
  textRight,
}: {
  data?: boolean | string;
  textLeft: string;
  textRight: string;
}) => {
  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  if (typeof data === 'string') {
    data = data.toLowerCase();
  }

  useEffect(() => {
    if (data === true || data === 'yes') {
      setDisabled(false);
      setChecked(true);
    } else if (data === false || data === 'no') {
      setDisabled(false);
      setChecked(false);
    } else if (data === '' || data === null || data === undefined) {
      setDisabled(true);
      setChecked(false);
    }
  }, [data]);

  return (
    <HStack spacing={4} mt={2}>
      <Box
        as="span"
        fontWeight={!checked && !disabled ? 'bold' : 'normal'}
        color={disabled ? 'saur.switch.disabled' : 'saur.black'}
      >
        {textLeft}
      </Box>
      <CustomSwitch checked={checked} disabled={disabled} />
      <Box
        as="span"
        fontWeight={checked && !disabled ? 'bold' : 'normal'}
        color={disabled ? 'saur.switch.disabled' : 'saur.black'}
      >
        {textRight}
      </Box>
    </HStack>
  );
};

export const SwitchToggleYesNo = ({ data }: { data?: boolean | string }) => {
  const { t } = useTranslation();

  return (
    <SwitchToggle
      textLeft={t('switchToggleComponent.no')}
      textRight={t('switchToggleComponent.yes')}
      data={data}
    />
  );
};

export const SwitchToggleManuelAuto = ({
  data,
}: {
  data?: boolean | string;
}) => {
  const { t } = useTranslation();

  return (
    <SwitchToggle
      textLeft={t('switchToggleComponent.manual')}
      textRight={t('switchToggleComponent.auto')}
      data={data}
    />
  );
};
