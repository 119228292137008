import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const NoResult = () => {
  const { t } = useTranslation();

  return (
    <VStack>
      <Text>{t('noResult')}</Text>
    </VStack>
  );
};
