// Enums
export enum DataType {
  Object,
  Data,
  Indicator,
}

export enum DataNature {
  Label,
  Definition,
}

// Primitive
export function isObject(dataType: number) {
  return dataType === DataType.Object;
}
export function isData(dataType: number) {
  return dataType === DataType.Data;
}
export function isIndicator(dataType: number) {
  return dataType === DataType.Indicator;
}
export function isLabel(dataNature: number) {
  return dataNature === DataNature.Label;
}
export function isDefinition(dataNature: number) {
  return dataNature === DataNature.Definition;
}

// Combinaison
export function isObjectLabel(dataType: number, dataNature: number) {
  return isObject(dataType) && isLabel(dataNature);
}
export function isObjectDefinition(dataType: number, dataNature: number) {
  return isObject(dataType) && isDefinition(dataNature);
}
export function isDataLabel(dataType: number, dataNature: number) {
  return isData(dataType) && isLabel(dataNature);
}
export function isDataDefinition(dataType: number, dataNature: number) {
  return isData(dataType) && isDefinition(dataNature);
}
export function isIndicatorLabel(dataType: number, dataNature: number) {
  return isIndicator(dataType) && isLabel(dataNature);
}
export function isIndicatorDefinition(dataType: number, dataNature: number) {
  return isIndicator(dataType) && isDefinition(dataNature);
}
