import '@fontsource/montserrat';
import 'primereact/resources/primereact.min.css';

import { KeycloakProvider } from 'modules/auth';
import { FeatureFlipProvider } from 'modules/feature-flips';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraProvider } from '@chakra-ui/react';
import { HashRouter } from 'react-router-dom';
import { Navigations } from './Navigations';
import { theme } from 'modules/theme';
import { env } from 'config/environment';
import { ErrorBoundary } from 'react-error-boundary';
import { Error } from 'components/layout';
import { MatomoProviderProps } from '@datapunt/matomo-tracker-react/lib/MatomoProvider';

function Matomo({
  children,
  value,
}: { children: React.ReactNode } & MatomoProviderProps) {
  // @ts-ignore
  return <MatomoProvider value={value}>{children}</MatomoProvider>;
}

export default function App({ keycloak }: { keycloak: any }) {
  const queryClient = new QueryClient();
  const instance = createInstance({
    urlBase: env.matomo.url,
    siteId: env.matomo.siteId,
  });

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <KeycloakProvider instance={keycloak}>
        <FeatureFlipProvider>
          <Matomo value={instance}>
            <QueryClientProvider client={queryClient}>
              <ChakraProvider theme={theme}>
                <HashRouter>
                  <Navigations />
                </HashRouter>
              </ChakraProvider>
              <ReactQueryDevtools />
            </QueryClientProvider>
          </Matomo>
        </FeatureFlipProvider>
      </KeycloakProvider>
    </ErrorBoundary>
  );
}
