import axios from 'axios';
import { LocalStorage } from 'utils/localStorage';
import { env } from 'config/environment';

export const http = axios.create({
  baseURL: `${env.baseUrl}/api`,
  headers: {
    Accept: 'application/json',
  },
});

export const httpAdmin = axios.create({
  baseURL: `${env.baseUrlIntern}/api`,
  headers: {
    Accept: 'application/json',
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${LocalStorage.token.get()}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpAdmin.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${LocalStorage.token.get()}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
