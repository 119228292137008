import { useEffect, useState } from 'react';
import { BasePageContainer } from 'components/common/Page';
import { useTranslation } from 'react-i18next';
import { SubDomain } from 'features/data-heatmap/type';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoAction, MatomoCategory } from 'helpers/matomoTraking';
import {
  FilterButtonList,
  ButtonVariant,
  EnumButtonVariant,
  FilterButton,
} from 'components/FilterButton';
import * as DomainHelper from 'helpers/domain';
import {
  DataHeatMapCardList,
  LegendContentDataHeatMap,
  useSubDomain,
} from 'features/data-heatmap';
import { ResponsiveBox } from 'components/layout/ResponsiveBox';

export const DataHeatMapPage = () => {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();
  const [subDomainFiltered, setSubDomainFiltered] = useState<SubDomain[]>([]);
  const [value, setValue] = useState(1);
  const { isLoading, data } = useSubDomain();
  useEffect(() => {
    if (data && !isLoading) {
      setSubDomainFiltered(data.subDomains);
      setValue(1);
    }
  }, [data, isLoading]);

  const domains: FilterButton[] | undefined = data?.domains.map(
    (domain, index) => {
      let variant: ButtonVariant | undefined;
      let action: MatomoAction = MatomoAction.clickFilterInDataHeatMapPage;

      if (DomainHelper.isCustomers(domain)) {
        variant = EnumButtonVariant.CUSTOMERS;
        action = MatomoAction.clickFilterCustomersInDataHeatMapPage;
      } else if (DomainHelper.isOperations(domain)) {
        variant = EnumButtonVariant.OPERATIONS;
        action = MatomoAction.clickFilterOperationsInDataHeatMapPage;
      } else if (DomainHelper.isTransverseFunctions(domain)) {
        variant = EnumButtonVariant.TRANSVERSE_FUNCTIONS;
        action = MatomoAction.clickFilterTransverseFunctionsInDataHeatMapPage;
      } else if (DomainHelper.isIndicators(domain)) {
        variant = EnumButtonVariant.INDICATORS;
        action = MatomoAction.clickFilterIndicatorsInDataHeatMapPage;
      }

      return {
        value: index + 2,
        label: domain,
        variant: variant,
        matomoAction: action,
      };
    },
  );
  const filterList: FilterButton[] = [
    {
      value: 1,
      label: t('all'),
      matomoAction: MatomoAction.clickFilterAllInDataHeatMapPage,
    },
    ...(domains ?? []),
  ];

  const handleChange = (newValue: number) => {
    setValue(newValue);
    const button = filterList.find((fb) => fb.value === newValue);
    const subDomains = data?.subDomains ?? [];

    if (!button) {
      return;
    }

    const filter =
      button.value === 1
        ? subDomains
        : subDomains.filter((sd) => sd.domainName === button.label);
    setSubDomainFiltered(filter);

    trackEvent({
      category: MatomoCategory.searchResultPage,
      action: button.matomoAction,
      name: `${button?.label}`,
    });
  };

  return (
    <BasePageContainer
      title={t('dataheatmap.title')}
      loading={isLoading || !data}
      legendContent={<LegendContentDataHeatMap domains={data?.domains ?? []} />}
    >
      <ResponsiveBox
        gap={4}
        mb={8}
        w={{ base: '4xl', xl: '5xl', '2xl': '8xl' }}
      >
        <FilterButtonList
          currentValue={value}
          list={filterList}
          onClick={handleChange}
        />
        <DataHeatMapCardList cards={subDomainFiltered} />
      </ResponsiveBox>
    </BasePageContainer>
  );
};
