import { Navigate, Route, Routes } from 'react-router-dom';
import {
  SearchPage,
  SearchResultPage,
  ObjectDetailsPage,
  DataDetailsPage,
  IndicatorDetailsPage,
  HomeCreatePage,
  DataHeatMapPage,
  AdminPage,
  FaqPage,
} from 'pages';
import {
  RedirectionAuthorizationAdmin,
  RedirectionAuthorizationEdit,
} from 'modules/auth';
import { useFeatureFlip } from 'modules/feature-flips';
import { Layout } from 'components/layout';

export const Navigations = () => {
  const { create } = useFeatureFlip();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<SearchPage />} />
        <Route path="/result" element={<SearchResultPage />} />
        <Route path="/object/:id" element={<ObjectDetailsPage />} />
        <Route path="/data/:id" element={<DataDetailsPage />} />
        <Route path="/indicator/:id" element={<IndicatorDetailsPage />} />
        <Route path="/data-heatmap" element={<DataHeatMapPage />} />
        {create && (
          <Route
            path="/create"
            element={
              <RedirectionAuthorizationEdit>
                <HomeCreatePage />
              </RedirectionAuthorizationEdit>
            }
          />
        )}
        <Route
          path="/admin"
          element={
            <RedirectionAuthorizationAdmin>
              <AdminPage />
            </RedirectionAuthorizationAdmin>
          }
        />
        <Route path="/faq" element={<FaqPage />} />

        {/* Allways at the end of Routes */}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Route>
    </Routes>
  );
};
