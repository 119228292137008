import { ReactComponent as LogoDatadico } from 'assets/header/LogoSaurDataDico.svg';
import profilImg from 'assets/header/group.png';
import {
  Link as RouterLink,
  LinkProps,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthorizationEdit } from 'modules/auth/authorization';
import { ReactComponent as MenuDecorationLogo } from 'assets/header-active.svg';
import { useKeycloak } from 'modules/auth/keycloak';
import {
  Box,
  chakra,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { HeaderLayout } from './HeaderLayout';
import { useFeatureFlip } from 'modules/feature-flips';
import { HeaderNavItem } from './HeaderNavItem';
import { MenuConfig } from './types';
import { useStore } from 'store';

const WaveMenuLogo = chakra(MenuDecorationLogo, {
  baseStyle: {
    color: '#008bd2',
  },
});

const NavItem = ({ children, to, ...props }: LinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Flex direction="column" align="center">
      <HeaderNavItem to={to} fontSize="0.813rem" {...props}>
        {children}
      </HeaderNavItem>
      {match && <WaveMenuLogo />}
    </Flex>
  );
};

export const Header = () => {
  const { name } = useKeycloak();
  const { t } = useTranslation();
  const { authorize: authEdit } = useAuthorizationEdit();
  const { create } = useFeatureFlip();
  const toggleProfilMenu = useStore((state) => state.toggleProfilMenu);

  const menuLeft: MenuConfig[] = [
    {
      to: '/',
      name: t('header.search'),
      visible: true,
    },
    {
      to: '/data-heatmap',
      name: t('header.data-heatmap'),
      visible: true,
    },
    {
      to: '/faq',
      name: t('header.faq'),
      visible: true,
    },
  ];
  const menuRight: MenuConfig[] = [
    {
      to: '/create',
      name: t('header.create'),
      visible: authEdit && create,
    },
  ];

  const mapMenu = (menu: MenuConfig[]) =>
    menu.map(({ to, name, visible }) =>
      visible ? (
        <NavItem key={to} to={to}>
          {name}
        </NavItem>
      ) : null,
    );

  return (
    <HeaderLayout>
      <Flex align="center" h="full" px="10">
        <HStack gap="9rem">
          <RouterLink to="/">
            <LogoDatadico />
          </RouterLink>
          <Flex gap="3.5rem" id="menu-control-left">
            {mapMenu(menuLeft)}
          </Flex>
        </HStack>
        <Spacer />
        <HStack gap="9rem">
          <Flex gap="3.5rem" id="menu-control-right">
            {mapMenu(menuRight)}
          </Flex>
          <Box as="button" type="button" onClick={toggleProfilMenu}>
            <HStack>
              <Text color="white" fontSize="sm">
                {name || 'undefined'}
              </Text>
              <Image id="img-rightlogo" alt="logo" src={profilImg} />
            </HStack>
          </Box>
        </HStack>
      </Flex>
    </HeaderLayout>
  );
};
