import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export const LegendPanel = ({ children }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <>
      <chakra.button
        ref={btnRef}
        onClick={onOpen}
        aria-label="legend-panel"
        type="button"
      >
        <InfoOutlineIcon color="saur.blue" boxSize={5} />
      </chakra.button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent borderLeftRadius="2xl">
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack spacing={4}>
              <InfoOutlineIcon color="saur.blue" boxSize={5} />
              <Heading fontSize="md">{t('legend.title')}</Heading>
            </HStack>
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
