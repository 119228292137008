import { SearchResult } from './type';
import { SearchResultItem } from './SearchResultItem';
import { VStack } from '@chakra-ui/react';

type Props = {
  list: SearchResult[];
};
export const SearchResultList = ({ list }: Props) => {
  const items = list.map((result, index) => (
    <SearchResultItem key={`search-result-item-${index}`} data={result} />
  ));
  return <VStack spacing={4}>{items}</VStack>;
};
