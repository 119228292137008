import { useTranslation } from 'react-i18next';
import { Box, Heading, Text } from '@chakra-ui/react';

export const CommentSection = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Heading fontSize="sm" color="saur.greyTabs" fontWeight="normal">
        {t('commentComponentTitle')}
      </Heading>
      <Text fontSize="sm">{text}</Text>
    </Box>
  );
};
