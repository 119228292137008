import { MenuCard } from './types';
import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { isJSXElement } from 'utils/element';

export const MenuCardItem = ({
  title,
  description,
  logo,
  action,
}: MenuCard) => {
  return (
    <Flex
      direction="column"
      bg="white"
      w="34.625rem"
      h="18.125rem"
      p={5}
      borderRadius="2xl"
      boxShadow="0 0.875rem 0.875rem 0.188rem rgba(0, 0, 0, 0.05)"
    >
      <Heading fontSize="md">{title.toUpperCase()}</Heading>
      <SimpleGrid columns={2} h="full">
        <Flex direction="column" mt={7}>
          <Text>{description}</Text>
          <Spacer />
          <div>
            {isJSXElement(action) ? (
              action
            ) : (
              <>
                <Box fontSize="sm" textAlign="left" fontWeight="bold">
                  {action}
                </Box>
                <Box mt={1} bg="#cce8f6" borderRadius="3xl" w={6} h={1.5} />
              </>
            )}
          </div>
        </Flex>
        <Flex align="center" justify="center">
          <Image src={logo} alt={title} w="12.625rem" h="12.313rem" />
        </Flex>
      </SimpleGrid>
    </Flex>
  );
};
