import { useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { DataTabs, DataRow } from 'components/GridPanel';
import {
  BaseDetails,
  QualityTab,
  TabsPanelDetails,
  useData,
  useParamsSearchDetail,
  Path,
} from 'features/search';
import { SwitchToggleYesNo } from 'components/common/SwitchToggle';
import { TagWrapperDispo } from 'components/common/Tag';
import { CommentSection } from 'components/common/CommentSection';
import { DataType } from 'helpers/result';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LoadingSpinner } from 'components/layout/LoadingSpinner';
import { Icon } from '@chakra-ui/react';
import { BiCalendar } from 'react-icons/bi';
import * as Helper from 'helpers/result';
import { useNavigate } from 'react-router-dom';

export const DataDetailsPage = () => {
  const { id } = useParamsSearchDetail();
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  const { isLoading, data } = useData(id);
  const navigate = useNavigate();

  useEffect(() => {
    if (data && Helper.isIndicator(data.dataType)) {
      navigate(`/indicator/${data.id}`, { replace: true });
    }
  }, [data, navigate]);

  // Track page view
  useEffect(() => {
    trackPageView({ documentTitle: document.title, href: document.URL });
  }, [trackPageView]);

  if (isLoading || !data) return <LoadingSpinner />;

  const searchPath: Path[] = [
    {
      title: t('searchResultPath.domain'),
      label: data.domain,
    },
    {
      title: t('searchResultPath.subdomain'),
      label: data.subDomain,
    },
    {
      title: t('searchResultPath.businessObject'),
      label: data.businessObject?.label as string,
      link: `/object/${data.businessObject?.id}`,
    },
    {
      title: t('searchResultPath.dataName'),
      label: data.label,
    },
  ];

  const businessTab: DataRow[] = [
    [
      {
        title: t('itemTab.businessTab.contributor'),
        content: data.contributor,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataOwner'),
        content: data.productionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.businessTab.dataCustodian'),
        content: data.provisionManager,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
    ],
    [
      {
        title: t('itemTab.businessTab.synonymous'),
        content: data.synonyms,
      },
      {
        title: t('itemTab.businessTab.functionalRule'),
        content: data.businessRule,
      },
      {
        title: t('itemTab.businessTab.valueExample'),
        content: data.valueExample,
      },
    ],
    [
      {
        title: t('itemTab.businessTab.goldenSource'),
        content: data.goldenSource,
      },
      {
        title: t('itemTab.businessTab.otherApplication'),
        content: data.otherApplication,
      },
    ],
    <CommentSection text={data.comment} />,
  ];

  const constraintTab: DataRow[] = [
    [
      {
        title: t('itemTab.constraintTab.personalData'),
        content: <SwitchToggleYesNo data={data.personalData} />,
      },
      {
        title: t('itemTab.constraintTab.confidentialityLevel'),
        content: data.confidentialLevel,
      },
    ],
    [
      {
        title: t('itemTab.constraintTab.anonymisation'),
        content: <SwitchToggleYesNo data={data.anonymisation} />,
      },
      {
        title: t('itemTab.constraintTab.regulatoryConstraint'),
        content: data.contrainteReglementaire,
      },
      {
        title: t('itemTab.constraintTab.requiredRetention'),
        content: data.conservationRequise,
      },
    ],
  ];

  const applicationTab: DataRow[] = [
    [
      {
        title: t('itemTab.applicationTab.appName'),
        content: data.applicativeDescription?.masterApplication,
      },
      {
        title: t('itemTab.applicationTab.appObject'),
        content: data.applicativeDescription?.appObject,
      },
      {
        title: t('itemTab.applicationTab.appInfo'),
        content: data.applicativeDescription?.appInfo,
      },
      {
        title: t('itemTab.applicationTab.location'),
        content: data.applicativeDescription?.location,
      },
    ],
    [
      {
        title: t('itemTab.applicationTab.techTableName'),
        content: data.applicativeDescription?.techTable,
      },
      {
        title: t('itemTab.applicationTab.techFieldName'),
        content: data.applicativeDescription?.techField,
      },
      {
        title: t('itemTab.applicationTab.dataType'),
        content: data.applicativeDescription?.type,
      },
      {
        title: t('itemTab.applicationTab.dataSize'),
        content: data.applicativeDescription?.length,
      },
    ],
    [
      {
        title: t('itemTab.applicationTab.mandatory'),
        content: (
          <SwitchToggleYesNo data={data.applicativeDescription?.mandatory} />
        ),
      },
      {
        title: t('itemTab.applicationTab.defaultValue'),
        content: data.applicativeDescription?.defaultValues,
      },
      {
        title: t('itemTab.applicationTab.unit'),
        content: data.applicativeDescription?.unit,
      },
      {
        title: t('itemTab.applicationTab.valueExample'),
        content: data.applicativeDescription?.valueExamples,
      },
    ],
    [
      {
        title: t('itemTab.applicationTab.format'),
        content: data.applicativeDescription?.format,
      },
      {
        title: t('itemTab.applicationTab.entry'),
        content: data.applicativeDescription?.entryType,
      },
      {
        title: t('itemTab.applicationTab.frequencyUpdate'),
        content: data.applicativeDescription?.updateFrequency,
      },
      {
        title: t('itemTab.applicationTab.functionalRule'),
        content: data.applicativeDescription?.functionalRule,
      },
      {
        title: t('itemTab.applicationTab.anonymizationRule'),
        content: data.applicativeDescription?.anonymisationRules,
      },
    ],
  ];

  const dataPlatformTab: DataRow[] = [
    [
      {
        title: t('itemTab.dataPlatformTab.coreDataModeled'),
        content: (
          <SwitchToggleYesNo
            data={data.dataPlateformDescription?.modeledData}
          />
        ),
      },
      {
        title: t('itemTab.dataPlatformTab.dataPlateformAvailability'),
        content: (
          <TagWrapperDispo
            data={data.dataPlateformDescription?.availableData}
          />
        ),
      },
      {
        title: t('itemTab.dataPlatformTab.coreModelSpecific'),
        content: (
          <SwitchToggleYesNo
            data={data.dataPlateformDescription?.specificCoreModeled}
          />
        ),
      },
    ],
    [
      {
        title: t('itemTab.dataPlatformTab.techTableName'),
        content: data.dataPlateformDescription?.techTable,
      },
      {
        title: t('itemTab.dataPlatformTab.techFieldName'),
        content: data.dataPlateformDescription?.techField,
      },
    ],
    [
      {
        title: t('itemTab.dataPlatformTab.linkTable'),
        content: data.dataPlateformDescription?.linkTable,
      },
      {
        title: t('itemTab.dataPlatformTab.description'),
        content: data.dataPlateformDescription?.description,
      },
    ],
  ];

  const updateTab: DataRow[] = [
    [
      {
        title: t('itemTab.updateTab.updateComment'),
        content: data.updates.progressInsertion,
      },
      {
        title: t('itemTab.updateTab.updateStatus'),
        content: data.updates.status,
      },
    ],
    [
      {
        title: t('itemTab.updateTab.updateUser'),
        content: data.updates.user,
        icon: <Icon as={AiOutlineUser} color="#D9C3FF" boxSize={8} />,
      },
      {
        title: t('itemTab.updateTab.updateDate'),
        content: data.updates.date.toString(),
        icon: <Icon as={BiCalendar} color="#D9C3FF" boxSize={8} />,
      },
    ],
  ];

  const tabsArray: DataTabs[] = [
    {
      label: t('itemTab.names.businessTab'),
      value: 'business',
      panel: businessTab,
    },
    {
      label: t('itemTab.names.constraintTab'),
      value: 'constraint',
      panel: constraintTab,
    },
    {
      label: t('itemTab.names.applicationTab'),
      value: 'application',
      panel: applicationTab,
    },
    {
      label: t('itemTab.names.dataPlatformTab'),
      value: 'data platform',
      panel: dataPlatformTab,
    },
    {
      label: t('itemTab.names.quality'),
      value: 'quality',
      panel: <QualityTab quality={data.quality} dataType={DataType.Data} />,
    },
    {
      label: t('itemTab.names.updateTab'),
      value: 'update',
      panel: updateTab,
    },
  ];

  return (
    <BaseDetails
      id={data.id}
      searchPath={searchPath}
      title={data.label}
      definition={data.definition}
      dataType={DataType.Data}
    >
      <TabsPanelDetails tabs={tabsArray} dataType={DataType.Data} />
    </BaseDetails>
  );
};
