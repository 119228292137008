import { SubDomainResult } from './type';
import { http } from 'utils/http';

export const getSubDomains = async (lang: string) => {
  const { data } = await http.get<SubDomainResult>(
    `/SubDomain?language=${lang}`,
  );

  return data;
};
