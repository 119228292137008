/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenButton } from 'components/common/Button';
import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Button,
  Heading,
  VStack,
  List,
  ListItem,
  ListIcon,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as FileLogo } from 'assets/import/file.svg';
import { ReactComponent as SuccessLogo } from 'assets/import/success.svg';
import { useRemoveImportResult } from './hooks';
import { LoadingSpinner } from 'components/layout';
import { ImportReportTable } from './ImportReportTable';
import { uploadImportFile, validateImport } from './api';

export const ImportButton = () => {
  const { t } = useTranslation();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFilesToUpload([...filesToUpload, ...acceptedFiles]);
    },
    [filesToUpload],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    maxFiles: 1,
  });
  const toast = useToast();
  const [activateImportResult, setActivateImportResult] = useState(false);
  const [activateValidate, setActivateValidate] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [validateModal, setValidateModal] = useState(false);
  const [importResult, setImportResult] = useState<any>([]);

  const addToastError = useCallback(
    (title: string, message: string) => {
      toast({
        title: title,
        description: message,
        status: 'error',
        isClosable: true,
        position: 'top-right',
      });
    },
    [toast],
  );

  // trigger verifying request
  useEffect(() => {
    if (loading && activateImportResult) {
      uploadImportFile(filesToUpload[0]).then((response: any) => {
        if (response.state === 'Success') {
          setImportResult(response.data);
          setShowReport(true);
        } else {
          addToastError('Error Import Analysis', `${response.errorMsg}`);
        }
        setLoading(false);
      });
    }
  }, [activateImportResult]);

  // trigger importing request in data base
  useEffect(() => {
    if (loading && activateValidate) {
      validateImport().then((response) => {
        if (response === 'Success') {
          setActivateValidate(false);
          setValidateModal(true);
        } else {
          addToastError('Error Import Validate', `${response}`);
        }
        setLoading(false);
      });
    }
  }, [activateValidate]);

  const { remove } = useRemoveImportResult();

  const handleImport = async () => {
    setLoading(true);
    setActivateImportResult(true);
  };

  const deleteFile = (file: File) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFilesToUpload(newFiles);
  };

  const handleValidate = () => {
    setLoading(true);
    setActivateImportResult(false);
    setActivateValidate(true);
  };

  const handleCancel = () => {
    onClose();
    setLoading(false);
    setFilesToUpload([]);
    setActivateImportResult(false);
    setActivateValidate(false);
    setValidateModal(false);
    setShowReport(false);
    setImportResult([]);
    remove();
  };

  const haveFiles = filesToUpload.length > 0;
  const styleFull = !validateModal ? { w: 'full' } : null;

  const files = filesToUpload.map((file) => (
    <ListItem key={file.name}>
      <HStack spacing={6}>
        <ListIcon as={FileLogo} w="49px" h="47px" />
        <Text fontSize="sm" fontWeight="semibold">
          {file.name}
        </Text>
        <Button
          onClick={() => deleteFile(file)}
          variant="link"
          color="saur.lightBlack"
          fontSize="0.688rem"
        >
          {t('import.deleteFileButton')}
        </Button>
      </HStack>
    </ListItem>
  ));

  return (
    <>
      <GreenButton onClick={onOpen}>
        {t('admin.menu.export-import.actionImport')}
      </GreenButton>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={importResult || validateModal ? 'xl' : '5xl'}
      >
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <VStack spacing="4" mb="16">
            <ModalHeader fontSize="lg" pb="0">
              {!validateModal ? (
                t('import.title')
              ) : (
                <VStack spacing="4">
                  <SuccessLogo />
                  <Box as="span">{t('import.validateTitle').toUpperCase()}</Box>
                </VStack>
              )}
            </ModalHeader>
            {!validateModal && (
              <Text fontSize="0.813rem">
                {importResult.length === 0
                  ? t('import.subtitleImport')
                  : t('import.subtitleReport')}
              </Text>
            )}
          </VStack>
          <ModalCloseButton />
          <ModalBody>
            <VStack {...styleFull}>
              <Flex flexDir="column" gap="2" {...styleFull}>
                {filesToUpload && !showReport && (
                  <>
                    <Heading as="h4" fontSize="sm" textAlign="left">
                      {t('import.file')}
                    </Heading>
                    {!haveFiles ? (
                      <Flex
                        flexDir="column"
                        border="2px dashed"
                        borderColor="saur.green.500"
                        borderRadius="3xl"
                        textAlign="center"
                        py={9}
                        px={7}
                        cursor="pointer"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <Box fontWeight="bold" fontSize="sm">
                          {t('import.dropzone')}
                          <Box color="saur.green.500">
                            {t('import.dropzoneSec')}
                          </Box>
                        </Box>
                      </Flex>
                    ) : (
                      <List spacing={4}>{files}</List>
                    )}
                  </>
                )}
                {loading && importResult.length > 0 && <LoadingSpinner />}
                {showReport && (
                  <Flex flexDir="column" gap={8} w="full">
                    {importResult.errorReport.length > 0 && (
                      <ImportReportTable
                        reports={importResult.errorReport}
                        tableType="Error"
                      />
                    )}
                    {importResult.warningReport.length > 0 && (
                      <ImportReportTable
                        reports={importResult.warningReport}
                        tableType="Warning"
                      />
                    )}
                    {!validateModal && (
                      <Text fontSize="sm">
                        <VStack spacing={2}>
                          <Box as="span" fontWeight="semibold">
                            {t('import.reportMsgTitle')}
                          </Box>
                          <Box as="span" fontWeight="semibold">
                            {t('import.reportMsg')}
                          </Box>
                        </VStack>
                      </Text>
                    )}
                  </Flex>
                )}
                {validateModal && (
                  <Text fontSize="md" fontWeight="bold">
                    {t('import.validateMsg')}
                  </Text>
                )}
              </Flex>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center" mt={importResult ? 36 : 8}>
            {!validateModal ? (
              <HStack spacing={4}>
                <GreenButton variant="outline" onClick={handleCancel}>
                  {t('import.cancelButton')}
                </GreenButton>
                <GreenButton
                  disabled={!haveFiles}
                  onClick={
                    importResult.length === 0 ? handleImport : handleValidate
                  }
                  isLoading={loading}
                >
                  {importResult.length === 0
                    ? t('import.importButton')
                    : t('import.validateButton')}
                </GreenButton>
              </HStack>
            ) : (
              <GreenButton onClick={handleCancel}>
                {t('import.closeButton')}
              </GreenButton>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
