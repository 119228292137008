import {
  CheckBoxVariant,
  CustomCheckBox,
  EnumCheckBoxVariant,
} from 'components/common/CustomCheckBox';
import { TagDomainWrapper } from 'components/common/Tag';
import { SubDomainObject } from './type';
import { Link as RouterLink } from 'react-router-dom';
import { Heading, HStack, Link, VStack } from '@chakra-ui/react';
import { usePrefetchObject } from 'features/search';

type Props = {
  title: string;
  objects: SubDomainObject[];
  domain: string;
};
export const DataHeatMapCardItem = ({ title, objects, domain }: Props) => {
  return (
    <VStack
      spacing={5}
      p={5}
      bg="white"
      borderRadius="2xl"
      align="start"
      boxShadow="0 0.875rem 0.875rem 0.188rem rgba(0, 0, 0, 0.05)"
    >
      <HStack spacing={4}>
        <Heading fontSize="md">{title.toUpperCase()}</Heading>
        <TagDomainWrapper domain={domain} />
      </HStack>
      <VStack spacing={2} align="start">
        {objects.map((object) => (
          <ObjectItem key={object.id} object={object} />
        ))}
      </VStack>
    </VStack>
  );
};

const ObjectItem = ({ object }: { object: SubDomainObject }) => {
  const { prefetch } = usePrefetchObject(object.id);
  const { coreModeled, dataPlateformAvailability } = object;
  let variant: CheckBoxVariant = EnumCheckBoxVariant.GREY;
  let checked = false;

  if (coreModeled && dataPlateformAvailability) {
    checked = true;
    variant = EnumCheckBoxVariant.BLUE;
  }
  if (coreModeled && !dataPlateformAvailability) {
    checked = false;
    variant = EnumCheckBoxVariant.BLUE;
  }
  if (!coreModeled && dataPlateformAvailability) {
    checked = true;
    variant = EnumCheckBoxVariant.GREY;
  }
  if (!coreModeled && !dataPlateformAvailability) {
    checked = false;
    variant = EnumCheckBoxVariant.GREY;
  }

  const to = `/object/${object.id}`;

  return (
    <HStack spacing={4}>
      <CustomCheckBox checked={checked} variant={variant} />
      <Link as={RouterLink} to={to} onMouseEnter={prefetch}>
        {object.name}
      </Link>
    </HStack>
  );
};
