import { Heading, Image, VStack, Text } from '@chakra-ui/react';
import { StatCard } from './types';

export const StatCardItem = ({ title, result, logo }: StatCard) => {
  return (
    <VStack
      bg="saur.stat.bg"
      w="12.063rem"
      h="10.688rem"
      p={5}
      borderRadius="2xl"
      boxShadow="0 0.625rem 0.875rem 0rem rgba(0, 0, 0, 0.05)"
    >
      <Image src={logo} alt={title} w={8} h="2.625rem" />
      <Text
        fontSize="4xl"
        color="saur.stat.result"
        textAlign="center"
        fontWeight="bold"
      >
        {result}
      </Text>
      <Heading fontSize="sm" as="h3" textAlign="center" color="saur.lightBlack">
        {title}
      </Heading>
    </VStack>
  );
};
