import { Button, ButtonProps } from '@chakra-ui/react';

export const GreenButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      rounded="full"
      colorScheme="saur.green"
      color={props.color ?? 'saur.black'}
      border="2px solid"
      borderColor="saur.green.500"
      fontWeight="bold"
      fontSize="sm"
      _disabled={{
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none',
        background: 'saur.undefined',
        borderColor: 'saur.undefined',
      }}
      _hover={{}}
      {...props}
    >
      {children}
    </Button>
  );
};
