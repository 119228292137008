import { GridPanelItem, DataTabs } from 'components/GridPanel';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Helper from 'helpers/result';
import { MatomoAction, MatomoCategory } from 'helpers/matomoTraking';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

type Props = {
  tabs: DataTabs[];
  dataType: number;
};

export const TabsPanelDetails = ({ tabs, dataType }: Props) => {
  const { trackEvent } = useMatomo();

  const matomoTracking = (tabLabel: string) => {
    let category: MatomoCategory | undefined;
    let action: MatomoAction | undefined;

    if (Helper.isObject(dataType)) {
      category = MatomoCategory.objectDetailsPage;
      action = MatomoAction.clickTabInObjectDetailsPage;
    } else if (Helper.isData(dataType)) {
      category = MatomoCategory.dataDetailsPage;
      action = MatomoAction.clickTabInDataDetailsPage;
    } else if (Helper.isIndicator(dataType)) {
      category = MatomoCategory.indicatorDetailsPage;
      action = MatomoAction.clickTabInIndicatorDetailsPage;
    }

    trackEvent({
      category: category ?? '',
      action: action ?? '',
      name: `${tabLabel}`,
    });
  };

  const tabsList = tabs.map((item) => (
    <Tab
      key={`tabs-${item.value}`}
      id={`tabs-${item.value}`}
      onClick={() => matomoTracking(item.label)}
      _selected={{ color: 'saur.black', bg: 'saur.green.500' }}
      rounded="full"
      fontWeight="bold"
      fontSize="sm"
    >
      {item.label}
    </Tab>
  ));

  const tabsPanel = tabs.map((item) => (
    <TabPanel key={`panel-${item.value}`} p={0}>
      <GridPanelItem item={item} decoration={true} delimitation={true} />
    </TabPanel>
  ));
  return (
    <Tabs variant="unstyled">
      <TabList bg="#ECF1F8" rounded="full" w="max">
        {tabsList}
      </TabList>
      <TabPanels>{tabsPanel}</TabPanels>
    </Tabs>
  );
};
