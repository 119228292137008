import { TagDomainWrapper } from 'components/common/Tag';
import {
  CheckBoxProps,
  CustomCheckBox,
  EnumCheckBoxVariant,
} from 'components/common/CustomCheckBox';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';

type Props = {
  domains: string[];
};
type Status = CheckBoxProps & { name: string };

export const LegendContentDataHeatMap = ({ domains }: Props) => {
  const { t } = useTranslation();

  const objectStatus: Status[] = [
    {
      checked: true,
      variant: EnumCheckBoxVariant.BLUE,
      name: t('legend.dataHeatMap.status.one'),
    },
    {
      checked: false,
      variant: EnumCheckBoxVariant.BLUE,
      name: t('legend.dataHeatMap.status.two'),
    },
    {
      checked: true,
      variant: EnumCheckBoxVariant.GREY,
      name: t('legend.dataHeatMap.status.three'),
    },
    {
      checked: false,
      variant: EnumCheckBoxVariant.GREY,
      name: t('legend.dataHeatMap.status.four'),
    },
  ];

  return (
    <Flex flexDir="column" gap={8}>
      <Flex flexDir="column" gap={4}>
        <Heading fontSize="sm" fontWeight="normal">
          {t('legend.dataHeatMap.subtitleDomain')}
        </Heading>
        <Flex flexDir="column" gap={2}>
          {domains.map((domain) => (
            <HStack key={domain} spacing={2}>
              <TagDomainWrapper domain={domain} />
              <Text fontSize="sm" fontWeight="bold">
                {domain}
              </Text>
            </HStack>
          ))}
        </Flex>
      </Flex>
      <Box as="span" border="4px solid #ecf1f8" />
      <Flex flexDir="column" gap={4}>
        <Heading fontSize="sm" fontWeight="normal">
          {t('legend.dataHeatMap.subtitleObjects')}
        </Heading>
        <Flex flexDir="column" gap={2}>
          {objectStatus.map(({ checked, variant, name }) => (
            <HStack key={name} spacing={2}>
              <CustomCheckBox checked={checked} variant={variant} />
              <Text fontSize="sm" color="saur.greyTabs">
                {name}
              </Text>
            </HStack>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
