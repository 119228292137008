import { InputSwitch } from 'primereact/inputswitch';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import './CustomSwitch.css';

export const CustomSwitch = ({
  checked,
  disabled,
}: {
  checked: boolean;
  disabled: boolean;
}) => {
  return (
    <InputSwitch className="material" checked={checked} disabled={disabled} />
  );
};
