import { DataDicoRole } from 'modules/auth/roles.helper';
import { useKeycloak } from 'modules/auth/keycloak';

type Authorization = {
  authorize: boolean;
};

function useAuthorize(role: DataDicoRole): Authorization {
  const { roles } = useKeycloak();
  const access = roles.includes(role);
  return { authorize: access };
}

export function useAuthorizationAdmin(): Authorization {
  return useAuthorize(DataDicoRole.admin);
}

export function useAuthorizationEdit(): Authorization {
  const admin = useAuthorize(DataDicoRole.admin);
  const edit = useAuthorize(DataDicoRole.edit);
  return { authorize: admin.authorize || edit.authorize };
}

export function useAuthorizationConsult(): Authorization {
  const admin = useAuthorize(DataDicoRole.admin);
  const edit = useAuthorize(DataDicoRole.edit);
  const consult = useAuthorize(DataDicoRole.consult);
  return { authorize: admin.authorize || edit.authorize || consult.authorize };
}
