import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getSubDomains } from './api';

export function useSubDomain() {
  const { i18n } = useTranslation();

  return useQuery(
    ['dataheatmap', i18n.language],
    () => getSubDomains(i18n.language),
    {
      enabled: !!i18n.language,
      staleTime: 10000,
    },
  );
}
