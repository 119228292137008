import { Data, DataRow, DataTabs } from 'components/GridPanel/types';
import { isJSXElement } from 'utils/element';
import { Box, Flex, Grid, GridItem, Heading, HStack } from '@chakra-ui/react';

const DataWrapper = ({
  title,
  content,
  icon,
  isLargeItem = false,
  isMediumItem = false,
}: Data) => {
  return (
    <GridItem colSpan={isMediumItem ? 2 : undefined}>
      <HStack spacing={2} p={2}>
        {icon}
        <Box w={isLargeItem ? 'full' : undefined}>
          <Heading fontSize="sm" fontWeight="normal" color="saur.greyTabs">
            {title}
          </Heading>
          <Box fontSize="sm">{content || '-'}</Box>
        </Box>
      </HStack>
    </GridItem>
  );
};
type Props = {
  item: DataTabs;
  delimitation: boolean;
  decoration: boolean;
};
export const GridPanelItem = ({ item, delimitation, decoration }: Props) => {
  const isLargeRow = (row: Data) => {
    return row.isLargeItem ?? (row.content?.toString().length ?? 0) > 150;
  };
  return (
    <Flex flexDir="column" mt={2}>
      {isJSXElement(item.panel)
        ? item.panel
        : item.panel?.map((row, index, array) => {
            let rowDisplayOrder: DataRow[] = [];
            let isRowLargeIndex: boolean[] = [];
            if (isJSXElement(row)) rowDisplayOrder.push(row);
            else if (row.length > 0) {
              let displayOrderIdx = -1;
              let isLarge = !isLargeRow(row[0]);
              for (let data of row) {
                let dataIsLarge = isLargeRow(data);
                if (dataIsLarge === isLarge) {
                  (rowDisplayOrder[displayOrderIdx] as Data[]).push(data);
                } else {
                  isLarge = !isLarge;
                  rowDisplayOrder.push([data]);
                  displayOrderIdx++;
                  isRowLargeIndex.push(dataIsLarge);
                }
              }
            }
            return (
              <Flex
                flexDir="row"
                py={4}
                gap={7}
                borderBottom={
                  delimitation && index !== array.length - 1
                    ? '0.125rem solid #ecf1f8 '
                    : undefined
                }
                key={`panel-${item.value}-row-${index}`}
              >
                {decoration && (
                  <Box as="span" w={5} bgColor="#ecf1f8" borderRadius="xl" />
                )}
                <Box w="95%">
                  {rowDisplayOrder.map((row, index) => {
                    const isLargeItem: boolean = !isJSXElement(row)
                      ? isRowLargeIndex[index]
                      : false;
                    return (
                      <Grid
                        templateColumns={
                          isLargeItem
                            ? 'repeat(1, minmax(0, 1fr))'
                            : 'repeat(3, minmax(0, 1fr))'
                        }
                        w="full"
                        alignItems="flex-start"
                        key={`panel-row-${index}`}
                      >
                        {isJSXElement(row)
                          ? row
                          : row.map((item) => (
                              <DataWrapper
                                key={item.title}
                                isLargeItem={isLargeItem}
                                isMediumItem={item.isMediumItem}
                                title={item.title}
                                content={item.content}
                                icon={item.icon}
                              />
                            ))}
                      </Grid>
                    );
                  })}
                </Box>
              </Flex>
            );
          })}
    </Flex>
  );
};
