import { mode, Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: (props) => ({
    html: {
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
      color: mode('saur.black', 'whiteAlpha.900')(props),
    },
  }),
};
