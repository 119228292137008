import { ObjectData } from './type';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoCategory, MatomoAction } from 'helpers/matomoTraking';
import {
  Flex,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { usePrefetchData, usePrefetchIndicator } from './hooks';
import * as Helper from 'helpers/result';

export const ObjectDataList = ({ dataList }: { dataList: ObjectData[] }) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table
        variant="striped"
        colorScheme="saur.greyTable"
        style={{ tableLayout: 'fixed' }}
        fontSize="sm"
      >
        <Thead>
          <Tr>
            <Th>{t('searchResultPath.dataName')}</Th>
            <Th>{t('definition')}</Th>
            <Th w="5%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {dataList.map((data) => (
            <DataTableLine key={data.id} data={data} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const DataTableLine = ({ data }: { data: ObjectData }) => {
  const { trackEvent } = useMatomo();
  const { prefetch: prefetchData } = usePrefetchData(data.id);
  const { prefetch: prefectchIndicator } = usePrefetchIndicator(data.id);

  const handleClick = () => {
    trackEvent({
      category: MatomoCategory.objectDetailsPage,
      action: MatomoAction.clickDataDetailsInObjectDetailsPage,
      name: data.label,
    });
  };
  const { prefetch, route } = Helper.isIndicator(data.dataType)
    ? { prefetch: prefectchIndicator, route: `/indicator/${data.id}` }
    : { prefetch: prefetchData, route: `/data/${data.id}` };

  return (
    <Tr>
      <Td wordBreak="break-word" whiteSpace="normal">
        {data.label}
      </Td>
      <Td noOfLines={1} display="table-cell">
        {data.definition}
      </Td>
      <Td>
        <Flex flexDir="row" justify="flex-end">
          <Link
            as={RouterLink}
            to={route}
            aria-label="access details"
            color="saur.blue"
            onClick={handleClick}
            onMouseEnter={prefetch}
          >
            <ViewIcon boxSize={5} />
          </Link>
        </Flex>
      </Td>
    </Tr>
  );
};
