import { Masonry } from 'components/layout/Masonry';
import { SubDomain } from './type';
import { DataHeatMapCardItem } from './DataHeatMapCardItem';

type Props = {
  cards: SubDomain[];
};
export const DataHeatMapCardList = ({ cards }: Props) => {
  const cardList = cards.map((subDomain, index) => (
    <DataHeatMapCardItem
      key={index + '-' + subDomain.name}
      title={subDomain.name}
      objects={subDomain.objects}
      domain={subDomain.domainName}
    />
  ));

  return <Masonry>{cardList}</Masonry>;
};
